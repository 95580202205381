import { Pipe, PipeTransform } from '@angular/core';
import { OrderStage } from 'src/app/enums/order-stage';
import { FieldWithPermission } from 'src/app/models/helpers.model';
import { Role } from 'src/app/models/role.model';

@Pipe({
  name: 'optionsFilter',
})
export class OptionsFilterPipe implements PipeTransform {
  transform(
    items: { title: string; value: number }[],
    filterValue: FieldWithPermission,
    userRole: Role,
    stage: OrderStage
  ): any {
    if (
      (stage === 0 &&
        userRole.name !== 'Customers' &&
        userRole.name !== 'CmpAdmin' &&
        !userRole.parentRole) ||
      (stage === 0 &&
        userRole.parentRole &&
        userRole.parentRole.name !== 'CmpAdmin' &&
        userRole.parentRole.name !== 'Customers')
    ) {
      return items.filter((item) => item.value !== 2);
    }

    if (
      (stage === 1 && userRole.name !== 'CmpAdmin' && !userRole.parentRole) ||
      (stage === 1 &&
        userRole.parentRole &&
        userRole.parentRole.name !== 'CmpAdmin')
    ) {
      return items.filter((item) => item.value !== 2);
    }

    if (
      (stage === 2 &&
        userRole.name !== 'ColdStorage' &&
        userRole.name !== 'CmpAdmin' &&
        !userRole.parentRole) ||
      (stage === 2 &&
        userRole.parentRole &&
        userRole.parentRole.name !== 'CmpAdmin' &&
        userRole.parentRole.name !== 'ColdStorage')
    ) {
      return items.filter((item) => item.value !== 2);
    }

    if (
      (stage === 3 &&
        userRole.name !== 'Customers' &&
        userRole.name !== 'CmpAdmin' &&
        !userRole.parentRole) ||
      (stage === 3 &&
        userRole.parentRole &&
        userRole.parentRole.name !== 'CmpAdmin' &&
        userRole.parentRole.name !== 'Customers')
    ) {
      return items.filter((item) => item.value !== 2);
    }

    if (!filterValue.isEditable) {
      return items.filter((item) => item.value !== 2);
    }
    return items;
  }
}
