import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { FileMetadata, FileSrc } from 'src/app/models/helpers.model';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
})
export class PdfPreviewComponent implements OnChanges, OnDestroy {
  @Input() fileData!: File | FileSrc;
  @Output() modalState = new EventEmitter<{
    isClose: boolean;
  }>();
  showDoc = false;
  fileName!: string;
  fileId!: string;
  srcData!: Uint8Array | FileSrc;
  private destroy = new Subject<boolean>();
  constructor(private attachmentsService: AttachmentsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    let changedValue = changes['fileData'].currentValue;
    if (this.isFileSrc(changedValue)) {
      this.srcData = changedValue;
      this.fileId = changedValue.url.split('/').reverse()[0];
      this.getFileMeta(this.fileId);
      this.renderDoc();
    } else {
      this.fileName = changedValue.name;
      changedValue.arrayBuffer().then((b: any) => {
        this.srcData = new Uint8Array(b);
        this.renderDoc();
      });
    }
  }

  isFileSrc(data: FileSrc | Uint8Array): data is FileSrc {
    return (data as FileSrc).withCredentials !== undefined;
  }

  getFileMeta(id: string) {
    this.attachmentsService
      .getMetadata(id)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (resp: FileMetadata) => {
          this.fileName = resp.fileName;
        },
      });
  }

  renderDoc() {
    if (this.srcData) {
      setTimeout(() => {
        (document.querySelector('.doc-content') as HTMLElement).style.width =
          '620px';
        (
          document.querySelector('.ng2-pdf-viewer-container') as HTMLElement
        ).style.width = '620px';
        this.showDoc = true;
      }, 500);
    } else {
      this.showDoc = false;
    }
  }

  backdropClick(event: Event) {
    if (event.target === event.currentTarget) {
      this.modalState.emit({ isClose: true });
    }
  }

  printDoc() {
    if (!this.isFileSrc(this.srcData)) {
      (this.fileData as File).arrayBuffer().then((b) => {
        let arrBuffer = new Uint8Array(b);
        let dataView = new DataView(arrBuffer.buffer);
        const blob = new Blob([dataView], { type: 'application/pdf' });
        const frameEl = document.createElement('iframe');
        frameEl.style.display = 'none';
        frameEl.src = URL.createObjectURL(blob);
        document.body.appendChild(frameEl);
        frameEl.onload = () => {
          frameEl.contentWindow?.focus();
          frameEl.contentWindow?.print();
        };
      });
    } else {
      this.attachmentsService
        .getAttachment(this.fileId)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: (resp: any) => {
            const blob = new Blob([resp], { type: 'application/pdf' });
            const frameEl = document.createElement('iframe');
            frameEl.style.display = 'none';
            frameEl.src = URL.createObjectURL(blob);
            document.body.appendChild(frameEl);
            frameEl.onload = () => {
              frameEl.contentWindow?.focus();
              frameEl.contentWindow?.print();
            };
          },
        });
    }
  }

  downloadDoc() {
    if (!this.isFileSrc(this.srcData)) {
      (this.fileData as File).arrayBuffer().then((b) => {
        let arrBuffer = new Uint8Array(b);
        let dataView = new DataView(arrBuffer.buffer);
        const blob = new Blob([dataView], { type: 'application/pdf' });
        let anchorEl = document.createElement('a');
        anchorEl.href = URL.createObjectURL(blob);
        anchorEl.setAttribute('download', `${(this.fileData as File).name}`);
        anchorEl.click();
      });
    } else {
      this.attachmentsService
        .getAttachment(this.fileId)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: (resp: any) => {
            const blob = new Blob([resp], { type: 'application/pdf' });
            let anchorEl = document.createElement('a');
            anchorEl.href = URL.createObjectURL(blob);
            anchorEl.setAttribute(
              'download',
              `${(this.fileData as File).name}`
            );
            anchorEl.click();
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
