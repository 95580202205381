<div class="table-box consignee-orders-table-box">
  <table
    mat-table
    [dataSource]="dataSource"
    class="data-table orders-table consignee-orders-table"
  >
    <ng-container matColumnDef="poNumber">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('poNumber')"
      >
        <div class="table-header-wrapper">
          P.O. Number
          <div class="sort-controls" *ngIf="selectedSort.field === 'poNumber'">
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div class="sort-controls" *ngIf="selectedSort.field !== 'poNumber'">
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div class="order-num">
          <a [routerLink]="['../../orders', order.id]"> {{ order.poNumber }}</a>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="orderNumber">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('orderNumber')"
      >
        <div class="table-header-wrapper">
          Order Number
          <div
            class="sort-controls"
            *ngIf="selectedSort.field === 'orderNumber'"
          >
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div
            class="sort-controls"
            *ngIf="selectedSort.field !== 'orderNumber'"
          >
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div class="order-num">
          <a [routerLink]="['../../orders', order.id]">
            {{ order.orderNumber ? order.orderNumber : "-" }}</a
          >
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="stage">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('stage')"
      >
        <div class="table-header-wrapper">
          Stage
          <div class="sort-controls" *ngIf="selectedSort.field === 'stage'">
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div class="sort-controls" *ngIf="selectedSort.field !== 'stage'">
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div>
          <div class="stage-hidden-title" style="display: none">
            <span *ngIf="order.stage === orderStage.Stage1"
              >Stage 1 &nbsp;</span
            >
            <span *ngIf="order.stage === orderStage.Stage2A"
              >Stage 2A &nbsp;</span
            >
            <span *ngIf="order.stage === orderStage.Stage2B"
              >Stage 2B &nbsp;</span
            >
            <span *ngIf="order.stage === orderStage.Stage3"
              >Stage 3 &nbsp;</span
            >
            <span *ngIf="order.stage === orderStage.Stage4"
              >Stage 4 &nbsp;</span
            >
          </div>
          <div class="stage-title" *ngIf="order.stage === orderStage.Stage1">
            Customer
          </div>
          <div
            class="stage-title"
            *ngIf="
              order.stage === orderStage.Stage2A ||
              order.stage === orderStage.Stage3
            "
          >
            CMP Invertory
          </div>
          <div
            class="stage-title"
            *ngIf="
              order.stage === orderStage.Stage2B ||
              order.stage === orderStage.Stage4
            "
          >
            Cold Storage
          </div>
          <div class="stage-diagram">
            <div
              class="diagram-cell"
              [ngClass]="{
                'one-open':
                  !order.stage3CmpInventoryApprovedOn ||
                  !order.stage3CustomerApprovedOn,
                one:
                  order.stage3CmpInventoryApprovedOn &&
                  order.stage3CustomerApprovedOn
              }"
            ></div>
            <div
              class="diagram-cell"
              [ngClass]="{
                'two-open':
                  !order.stage3CmpInventoryApprovedOn ||
                  !order.stage3CustomerApprovedOn,
                two:
                  order.stage3CmpInventoryApprovedOn &&
                  order.stage3CustomerApprovedOn
              }"
            ></div>
            <div
              class="diagram-cell"
              [ngClass]="{
                'three-open':
                  !order.stage3CmpInventoryApprovedOn ||
                  !order.stage3CustomerApprovedOn,
                three:
                  order.stage3CmpInventoryApprovedOn &&
                  order.stage3CustomerApprovedOn
              }"
            ></div>
            <div
              class="diagram-cell"
              [ngClass]="{ four: order.stage > orderStage.Stage3 }"
            ></div>
            <div
              class="diagram-cell"
              [ngClass]="{ five: order.stage > orderStage.Stage4 }"
            ></div>
          </div>
          <div class="done-mark" *ngIf="order.status === 'closed'">
            <mat-icon>check_circle</mat-icon>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('status')"
      >
        <div class="table-header-wrapper">
          Status
          <div class="sort-controls" *ngIf="selectedSort.field === 'status'">
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div class="sort-controls" *ngIf="selectedSort.field !== 'status'">
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div
          class="status-label"
          [ngClass]="{
            'open-label': order.status === orderStatus.Open,
            'closed-label': order.status === orderStatus.Closed,
            'canceled-label': order.status === orderStatus.Canceled
          }"
        >
          <span class="status-mark"></span>
          <span class="status-text">{{
            order.status === 0
              ? "Open"
              : order.status === 1
              ? "Closed"
              : "Canceled"
          }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="consignee">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('consignee.name')"
      >
        <div class="table-header-wrapper">
          Consignee
          <div
            class="sort-controls"
            *ngIf="selectedSort.field === 'consignee.name'"
          >
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div
            class="sort-controls"
            *ngIf="selectedSort.field !== 'consignee.name'"
          >
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div>
          {{ order.consigneeName }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('customer.name')"
      >
        <div class="table-header-wrapper">
          Customer
          <div
            class="sort-controls"
            *ngIf="selectedSort.field === 'customer.name'"
          >
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div
            class="sort-controls"
            *ngIf="selectedSort.field !== 'customer.name'"
          >
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div>
          {{ order.customerName }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="coldStorage">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('coldStorage.name')"
      >
        <div class="table-header-wrapper">
          Cold Storage
          <div
            class="sort-controls"
            *ngIf="selectedSort.field === 'coldStorage.name'"
          >
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div
            class="sort-controls"
            *ngIf="selectedSort.field !== 'coldStorage.name'"
          >
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div>
          {{ order.coldStorageName }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="destination country">
      <th mat-header-cell *matHeaderCellDef>Destination Country</th>
      <td mat-cell *matCellDef="let order">
        <div>
          {{ getCountry(order.countryId) }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('createdOn')"
      >
        <div class="table-header-wrapper">
          Created
          <div class="sort-controls" *ngIf="selectedSort.field === 'createdOn'">
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div class="sort-controls" *ngIf="selectedSort.field !== 'createdOn'">
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div>
          {{ order.createdOn | date : "MM/dd/yyyy hh:mm a" }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="modified">
      <th
        mat-header-cell
        class="sortable-header"
        *matHeaderCellDef
        (click)="sortOrders('lastModifiedOn')"
      >
        <div class="table-header-wrapper">
          Modified
          <div
            class="sort-controls"
            *ngIf="selectedSort.field === 'lastModifiedOn'"
          >
            <img
              src="/assets/img/icons/sort_arrow_up.svg"
              alt="up"
              *ngIf="selectedSort.mode !== 'desc'"
            />
            <img
              src="/assets/img/icons/sort_arrow_down.svg"
              alt="down"
              *ngIf="selectedSort.mode !== 'asc'"
            />
          </div>
          <div
            class="sort-controls"
            *ngIf="selectedSort.field !== 'lastModifiedOn'"
          >
            <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
            <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let order">
        <div>
          {{ order.lastModifiedOn | date : "MM/dd/yyyy hh:mm a" }}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>

    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell no-data-cell" colspan="9">
        No data matching the filter
      </td>
    </tr>
  </table>
</div>
<app-paginator
  [records]="paginationData"
  (pageSettings)="getPageSettings($event)"
></app-paginator>
