import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Consignee } from '../models/consignee.model';
import { ResponseData } from '../models/helpers.model';
import { FilterOptions } from '../models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class ConsigneeService {
  constructor(private http: HttpClient) {}

  getConsignees(
    filterOptions: FilterOptions
  ): Observable<ResponseData<Consignee>> {
    return this.http.post<ResponseData<Consignee>>(
      '/consignees/filter',
      filterOptions
    );
  }

  getConsignee(id: string): Observable<Consignee> {
    return this.http.get<Consignee>(`/consignees/${id}`);
  }

  updateConsignee(id: string, data: Partial<Consignee>): Observable<string> {
    return this.http.put<string>(`/consignees/${id}`, data, {
      responseType: 'text' as 'json',
    });
  }

  deleteConsignee(id: string): Observable<string> {
    return this.http.delete<string>(`/consignees/${id}`, {
      responseType: 'text' as 'json',
    });
  }

  addConsignee(data: Partial<Consignee>): Observable<Consignee> {
    return this.http.post<Consignee>('/consignees', data);
  }
}
