<!-- <input type="number" [(ngModel)]="currentPage" /> -->
<div class="table-controls" *ngIf="records">
  <div class="items-per-page" *ngIf="records.totalCount !== 0">
    <div
      *ngIf="totalRecords > 15"
      class="per-page"
      [ngClass]="{ 'per-page-active': perPage === 15 }"
      (click)="selectPerPage(15)"
    >
      15
    </div>
    <div
      *ngIf="totalRecords > 15"
      class="per-page"
      [ngClass]="{ 'per-page-active': perPage === 25 }"
      (click)="selectPerPage(25)"
    >
      25
    </div>
    <div
      *ngIf="totalRecords > 25"
      class="per-page"
      [ngClass]="{ 'per-page-active': perPage === 50 }"
      (click)="selectPerPage(50)"
    >
      50
    </div>
  </div>
  <div
    class="paginator"
    *ngIf="records.totalCount !== 0 && totalRecords > perPage"
  >
    <button
      class="left-arrow arrow"
      [disabled]="currentPage === 1"
      (click)="selectPage(currentPage - 1)"
    >
      <img src="/assets/img/icons/left_arrow.svg" alt="left" />
      <span>Prev</span>
    </button>
    <button
      class="first-page page"
      *ngIf="currentPage >= 4"
      (click)="selectPage(1)"
    >
      1
    </button>
    <div class="dots" *ngIf="currentPage >= 5">...</div>
    <button
      class="prev-page-5 page"
      *ngIf="currentPage === numPages && numPages === 9"
      (click)="selectPage(currentPage - 6)"
    >
      {{ currentPage - 6 }}
    </button>
    <button
      class="prev-page-5 page"
      *ngIf="currentPage >= numPages - 1 && numPages === 9"
      (click)="selectPage(currentPage - 5)"
    >
      {{ currentPage - 5 }}
    </button>
    <button
      class="prev-page-4 page"
      *ngIf="currentPage >= numPages - 2 && numPages === 9"
      (click)="selectPage(currentPage - 4)"
    >
      {{ currentPage - 4 }}
    </button>
    <button
      class="prev-page-3 page"
      *ngIf="currentPage >= numPages - 3 && numPages === 9"
      (click)="selectPage(currentPage - 3)"
    >
      {{ currentPage - 3 }}
    </button>
    <button
      class="prev-page-2 page"
      *ngIf="currentPage > 2"
      (click)="selectPage(currentPage - 2)"
    >
      {{ currentPage - 2 }}
    </button>
    <button
      class="prev-page-1 page"
      *ngIf="currentPage > 1"
      (click)="selectPage(currentPage - 1)"
    >
      {{ currentPage - 1 }}
    </button>
    <button class="cur-page page" (click)="selectPage(currentPage)">
      {{ currentPage }}
    </button>
    <button
      class="next-page-1 page"
      *ngIf="currentPage < numPages - 1"
      (click)="selectPage(currentPage + 1)"
    >
      {{ currentPage + 1 }}
    </button>
    <button
      class="next-page-2 page"
      *ngIf="currentPage < numPages - 2"
      (click)="selectPage(currentPage + 2)"
    >
      {{ currentPage + 2 }}
    </button>
    <button
      class="next-page-3 page"
      *ngIf="currentPage < 5 && numPages === 9"
      (click)="selectPage(currentPage + 3)"
    >
      {{ currentPage + 3 }}
    </button>
    <button
      class="next-page-4 page"
      *ngIf="currentPage < 4 && numPages === 9"
      (click)="selectPage(currentPage + 4)"
    >
      {{ currentPage + 4 }}
    </button>
    <button
      class="next-page-5 page"
      *ngIf="currentPage < 3 && numPages === 9"
      (click)="selectPage(currentPage + 5)"
    >
      {{ currentPage + 5 }}
    </button>
    <button
      class="next-page-6 page"
      *ngIf="currentPage < 2 && numPages === 9"
      (click)="selectPage(currentPage + 6)"
    >
      {{ currentPage + 6 }}
    </button>
    <div class="dots" *ngIf="currentPage <= numPages - 4">...</div>
    <button
      class="last-page page"
      *ngIf="currentPage !== numPages"
      (click)="selectPage(numPages)"
    >
      {{ numPages }}
    </button>
    <button
      class="right-arrow arrow"
      [disabled]="currentPage === numPages"
      (click)="selectPage(currentPage + 1)"
    >
      <span>Next</span>
      <img src="/assets/img/icons/right_arrow.svg" alt="right" />
    </button>
  </div>
</div>
