export enum OrderStage {
  Stage1 = 0,
  Stage2A = 1,
  Stage2B = 2,
  Stage3 = 3,
  Stage4 = 5,
}

export enum OrderStageString {
  Stage1 = '0',
  Stage2A = '1',
  Stage2B = '2',
  Stage3 = '3',
  Stage4 = '5',
}

export enum OrderStageStringAlt {
  Stage1 = 'Stage1',
  Stage2A = 'Stage2A',
  Stage2B = 'Stage2B',
  Stage3 = 'Stage3',
  Stage4 = 'Stage4',
}
