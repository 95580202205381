import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  takeUntil,
  Subject,
  debounceTime,
  distinctUntilChanged,
  combineLatestWith,
} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Order, OrderDynamicField } from 'src/app/models/order.model';
import { OrderStage } from 'src/app/enums/order-stage';
import { OrderStatus } from 'src/app/enums/order-status';
import { OrdersService } from 'src/app/services/orders.service';
import { FilterOptions } from 'src/app/models/filter.model';
import {
  OrderTableColumn,
  PaginationData,
  ResponseData,
} from 'src/app/models/helpers.model';
import { LoadingService } from 'src/app/services/loading.service';
import { WorkSheet, WorkBook, utils, writeFile } from 'xlsx';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { Router } from '@angular/router';
import { OrderFieldService } from 'src/app/services/order-field.service';
import { NullTransformPipe } from 'src/app/dashboard/pipes/null-transform.pipe';
import { FieldType } from 'src/app/enums/field-type';
import { FilterOperator } from 'src/app/enums/filter-operator';
import { OrderField } from 'src/app/models/field-list.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CountriesService } from 'src/app/services/countries.service';
import { Country } from 'src/app/models/country.model';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
  providers: [NullTransformPipe],
})
export class OrdersTableComponent implements OnInit, OnDestroy {
  @Input() pageName!: string;
  @ViewChild('exportModal') exportModalEl!: ElementRef;
  countries: Country[] = [];
  countriesForSearch: Country[] = [];
  searchCountries: FormControl = new FormControl('');
  customFieldType = FieldType;
  orderStatus = OrderStatus;
  orderStage = OrderStage;
  paginationData!: PaginationData;
  showFilterModal = false;
  isOrdersLoaded = false;
  orders!: Order[];
  o!: Order[];
  dynamicFields!: OrderField[];
  ordersForXslx: Order[] = [];
  ordersStats = { open: 0, closed: 0, canceled: 0 };
  selectedCol = 'poNumber';
  filteredOrders!: any[];
  filterValue = '';
  tableColumns: string[] = [];
  reorderedColumns: string[] = [];
  reorderedColumnsB: string[] = [];
  columnsStates: boolean[] = [];
  columnsStatesBackup: boolean[] = [];
  columnsStatesBackupB: boolean[] = [];
  selectedFilter!: string;
  fieldType = FieldType;
  filteredFieldType = FieldType.Number;
  dataSource: any;
  isApplyFilters = true;
  summaryOptions: FilterOptions = {
    advancedSearch: {
      fields: [],
      keyword: '',
    },
    keyword: '',
    advancedFilter: null,
  };
  searchOptions: FilterOptions = {
    advancedSearch: {
      fields: [
        'poNumber',
        'orderNumber',
        'consignee.name',
        'customer.name',
        'coldStorage.name',
      ],
      keyword: '',
    },
    keyword: '',
    advancedFilter: null,
    pageNumber: 1,
    pageSize: 15,
    orderBy: [],
  };
  stageEnumValues = [
    { name: 'Stage 1', value: OrderStage.Stage1 },
    { name: 'Stage 2A', value: OrderStage.Stage2A },
    { name: 'Stage 2B', value: OrderStage.Stage2B },
    { name: 'Stage 3', value: OrderStage.Stage3 },
    { name: 'Stage 4', value: OrderStage.Stage4 },
  ];
  statusEnumValues = [
    { name: 'Open', value: OrderStatus.Open },
    { name: 'Canceled', value: OrderStatus.Canceled },
    { name: 'Closed', value: OrderStatus.Closed },
  ];
  searchOptionsAlt: FilterOptions = {
    advancedSearch: {
      fields: [],
      keyword: '',
    },
    keyword: '',
    advancedFilter: null,
    pageNumber: 1,
    pageSize: 1,
    orderBy: [],
  };
  searchString = '';
  selectedSort = { field: '', mode: '' };
  rangeFilter!: Array<{
    field: string;
    operator: string;
    value: string;
  }> | null;
  statusFilter!: {
    field: string;
    operator: string;
    value: string;
  } | null;
  customFilter!: any;
  allFieldFilters: Array<{
    name: string;
    type: 'text' | 'number' | 'bool' | 'enum' | 'date' | 'list';
    field: string;
    operator: string;
    value: string;
    isShow: boolean;
  }> = [];
  multiFilters: any;
  private destroy = new Subject<boolean>();
  private search = new Subject<string>();
  displayedColumns: string[] = [];
  tableColumnsObj: OrderTableColumn[] = [];
  reorderedColumnsObj: OrderTableColumn[] = [];

  constructor(
    private ordersService: OrdersService,
    private loadingService: LoadingService,
    private router: Router,
    private orderFieldService: OrderFieldService,
    private countriesService: CountriesService,
    private nullTransform: NullTransformPipe
  ) {}

  ngOnInit(): void {
    this.getFilterFields();
    this.filteredOrders = this.orders;
    this.search.pipe(debounceTime(2000), distinctUntilChanged()).subscribe({
      next: () => {
        this.searchOptions.advancedSearch.keyword = this.searchString;
        this.searchOptions.pageNumber = 1;
        this.getOrders(this.searchOptions);
      },
    });
    this.getCountries();
    this.searchCountries.valueChanges.pipe(takeUntil(this.destroy)).subscribe({
      next: (value: string) => {
        this.countries = this.countriesForSearch.filter((c: Country) =>
          c.name.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
  }

  isSettedFilter(col: string) {
    return (
      this.allFieldFilters.find((f) => f.name === col)?.value !== '' &&
      this.allFieldFilters.find((f) => f.name === col)?.operator !== ''
    );
  }

  getRange(range: { gte: string; lte: string }) {
    if (range.gte) {
      this.rangeFilter = [
        {
          field: 'createdOn',
          operator: FilterOperator.GreaterThanOrEqual,
          value: range.gte,
        },
        {
          field: 'createdOn',
          operator: FilterOperator.LessThanOrEqual,
          value: range.lte,
        },
      ];
      this.searchOptions.advancedFilter = {
        logic: 'and',
        filters: this.statusFilter
          ? [this.statusFilter, ...this.rangeFilter]
          : [...this.rangeFilter],
      };
    } else {
      this.rangeFilter = null;
      if (this.statusFilter) {
        this.searchOptions.advancedFilter = {
          logic: 'and',
          filters: [this.statusFilter],
        };
      } else {
        this.searchOptions.advancedFilter = null;
      }
    }
    this.applyMultiFilters();
  }

  getSummary(status: OrderStatus, searchOptions?: any[]) {
    const statusFilter = {
      field: 'status',
      operator: FilterOperator.Equals,
      value: status.toString(),
    };
    this.summaryOptions.advancedFilter = {
      logic: 'and',
      filters:
        searchOptions && this.rangeFilter?.length
          ? [statusFilter, ...searchOptions, ...this.rangeFilter]
          : !searchOptions && this.rangeFilter?.length
          ? [statusFilter, ...this.rangeFilter]
          : searchOptions && !this.rangeFilter?.length
          ? [statusFilter, ...searchOptions]
          : [statusFilter],
    };
    this.loadingService.changeLoadingStatus(true);
    this.ordersService
      .getOrdersSummary(this.summaryOptions)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (resp: number) => {
          if (status === this.orderStatus.Open) {
            this.ordersStats.open = resp;
          }
          if (status === this.orderStatus.Closed) {
            this.ordersStats.closed = resp;
          }
          if (status === this.orderStatus.Canceled) {
            this.ordersStats.canceled = resp;
          }

          this.loadingService.changeLoadingStatus(false);
        },
        error: () => {
          this.loadingService.changeLoadingStatus(false);
        },
      });
  }

  getCountries() {
    this.countriesService.contriesList.pipe(takeUntil(this.destroy)).subscribe({
      next: (resp: Country[] | null) => {
        if (resp) {
          this.countries = resp.sort((a: Country, b: Country) => {
            return a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : 0;
          });
          this.countriesForSearch = resp;
        }
      },
    });
  }

  checkIsEqual(a: Array<any>, b: Array<any>): boolean {
    if (Array.isArray(a) && Array.isArray(b)) {
      a = a.sort();
      b = b.sort();
      return a.length === b.length && a.every((el, i) => el === b[i]);
    }
    return false;
  }

  getFilterFields() {
    if (this.pageName === 'orders') {
      if (localStorage.getItem('table')) {
        this.tableColumns = [
          ...JSON.parse(<string>localStorage.getItem('table')).tableColumns,
        ];
        this.reorderedColumns = [
          ...JSON.parse(<string>localStorage.getItem('table')).reorderedColumns,
        ];
        this.columnsStates = [
          ...JSON.parse(<string>localStorage.getItem('table')).columnsStates,
        ];
        this.columnsStatesBackup = [
          ...JSON.parse(<string>localStorage.getItem('table')).columnsStates,
        ];
      } else {
        this.tableColumns = [];
        this.reorderedColumns = [];
        this.columnsStates = [];
        this.columnsStatesBackup = [];
      }
    } else {
      if (localStorage.getItem('reports_table')) {
        this.tableColumns = [
          ...JSON.parse(<string>localStorage.getItem('reports_table'))
            .tableColumns,
        ];
        this.reorderedColumns = [
          ...JSON.parse(<string>localStorage.getItem('reports_table'))
            .reorderedColumns,
        ];
        this.columnsStates = [
          ...JSON.parse(<string>localStorage.getItem('reports_table'))
            .columnsStates,
        ];
        this.columnsStatesBackup = [
          ...JSON.parse(<string>localStorage.getItem('reports_table'))
            .columnsStates,
        ];
      } else {
        this.tableColumns = [];
        this.reorderedColumns = [];
        this.columnsStates = [];
        this.columnsStatesBackup = [];
      }
    }

    this.reorderedColumnsB = [];
    this.columnsStatesBackupB = [];
    this.loadingService.changeLoadingStatus(true);
    this.ordersService
      .getOrders(this.searchOptionsAlt)
      .pipe(
        combineLatestWith(this.orderFieldService.getFields()),
        takeUntil(this.destroy)
      )
      .subscribe({
        next: ([resp, dynamicFields]) => {
          this.o = resp.data.map((order: any) => {
            const fields = [];
            for (let field of dynamicFields) {
              fields.push({
                name: field.name,
                type: field.type,
                isCustom: true,
                value: order.dynamicFields.find(
                  (df: OrderDynamicField) => df.dynamicFieldId === field.id
                )?.fieldValueDate
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueDate
                  : order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    )?.fieldValueStr
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueStr
                  : order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    )?.fieldValueNum
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueNum
                  : '',
              });
            }
            for (let f of fields) {
              order = {
                ...order,
                [f.name]: f,
              };
            }
            return order;
          });

          const tc: any[] = [];
          const cs: any[] = [];
          const rc: any[] = [];
          const csb: any[] = [];

          for (let key in this.o[0]) {
            if (
              key !== 'coldStorageId' &&
              key !== 'consigneeId' &&
              key !== 'customerId' &&
              key !== 'id' &&
              key !== 'isNew' &&
              key !== 'dynamicFields' &&
              key !== 'stage1ApprovedOn' &&
              key !== 'stage2AApprovedOn' &&
              key !== 'stage2BApprovedOn' &&
              key !== 'stage1NotifiedOn' &&
              key !== 'stage2ANotifiedOn' &&
              key !== 'stage2BNotifiedOn' &&
              key !== 'stage3ApprovedOn' &&
              key !== 'stage4ApprovedOn' &&
              key !== 'isCompleted' &&
              key !== 'stage3CmpInventoryApprovedOn' &&
              key !== 'stage3CustomerApprovedOn'
            ) {
              tc.push(key);
              cs.push(true);
              rc.push(key);
              csb.push(true);
              this.reorderedColumnsB.push(key);
              this.columnsStatesBackupB.push(true);
              this.allFieldFilters.push({
                name: key,
                type:
                  key === 'orderNumber' ||
                  key === 'poNumber' ||
                  key === 'customerName' ||
                  key === 'consigneeName' ||
                  key === 'coldStorageName' ||
                  key === 'countryId' ||
                  this.o[0][key]?.type === FieldType.Text ||
                  this.o[0][key]?.type === FieldType.List
                    ? 'text'
                    : this.o[0][key]?.type === FieldType.Number
                    ? 'number'
                    : key === 'createdOn' ||
                      key === 'lastModifiedOn' ||
                      this.o[0][key]?.type === FieldType.DateTime
                    ? 'date'
                    : key === 'stage' || key === 'status'
                    ? 'enum'
                    : 'bool',
                field:
                  key === 'customerName'
                    ? 'customer.name'
                    : key === 'consigneeName'
                    ? 'consignee.name'
                    : key === 'coldStorageName'
                    ? 'coldStorage.name'
                    : (this.o[0][key] &&
                        this.o[0][key].type === FieldType.Text) ||
                      (this.o[0][key] && this.o[0][key].type === FieldType.List)
                    ? 'dynamicFields.fieldValueStr'
                    : this.o[0][key] && this.o[0][key].type === FieldType.Number
                    ? 'dynamicFields.fieldValueNum'
                    : this.o[0][key] &&
                      this.o[0][key].type === FieldType.DateTime
                    ? 'dynamicFields.fieldValueDate'
                    : key,
                operator: 'Equals',
                value: '',
                isShow: true,
              });
            }
          }

          if (
            this.pageName === 'orders' &&
            !localStorage.getItem('tc_saved_orders')
          ) {
            this.tableColumns = [...tc];
            this.reorderedColumns = [...rc];
            this.columnsStates = [...cs];
            this.columnsStatesBackup = [...csb];
          }

          if (
            this.pageName === 'orders' &&
            localStorage.getItem('tc_saved_orders')
          ) {
            if (
              !this.checkIsEqual(
                [...tc],
                [...JSON.parse(<string>localStorage.getItem('tc_saved_orders'))]
              ) ||
              !this.tableColumns.length
            ) {
              rc.forEach((el) => {
                if (!this.reorderedColumns.includes(el)) {
                  this.reorderedColumns.push(el);
                  this.columnsStates.push(true);
                }
              });
              const tempReorderedC = [...this.reorderedColumns];
              tempReorderedC.forEach((el) => {
                if (!rc.includes(el)) {
                  let index = this.reorderedColumns.indexOf(el);
                  this.reorderedColumns.splice(index, 1);
                  this.columnsStates.splice(index, 1);
                }
              });
              this.columnsStatesBackup = [...this.columnsStates];
            }
          }

          if (
            this.pageName !== 'orders' &&
            !localStorage.getItem('tc_saved_reports')
          ) {
            this.tableColumns = [...tc];
            this.reorderedColumns = [...rc];
            this.columnsStates = [...cs];
            this.columnsStatesBackup = [...csb];
          }

          if (
            this.pageName !== 'orders' &&
            localStorage.getItem('tc_saved_reports')
          ) {
            if (
              !this.checkIsEqual(
                [...tc],
                [
                  ...JSON.parse(
                    <string>localStorage.getItem('tc_saved_reports')
                  ),
                ]
              ) ||
              !this.tableColumns.length
            ) {
              rc.forEach((el) => {
                if (!this.reorderedColumns.includes(el)) {
                  this.reorderedColumns.push(el);
                  this.columnsStates.push(true);
                }
              });
              const tempReorderedC = [...this.reorderedColumns];
              tempReorderedC.forEach((el) => {
                if (!rc.includes(el)) {
                  let index = this.reorderedColumns.indexOf(el);
                  this.reorderedColumns.splice(index, 1);
                  this.columnsStates.splice(index, 1);
                }
              });
              this.columnsStatesBackup = [...this.columnsStates];
            }
          }

          if (this.pageName === 'orders') {
            if (
              !localStorage.getItem('tc_saved_orders') ||
              !this.checkIsEqual(
                [...tc],
                [...JSON.parse(<string>localStorage.getItem('tc_saved_orders'))]
              )
            ) {
              localStorage.removeItem('tc_saved_orders');
              localStorage.setItem('tc_saved_orders', JSON.stringify(tc));
            }
          } else {
            if (
              !localStorage.getItem('tc_saved_reports') ||
              !this.checkIsEqual(
                [...tc],
                [
                  ...JSON.parse(
                    <string>localStorage.getItem('tc_saved_reports')
                  ),
                ]
              )
            ) {
              localStorage.removeItem('tc_saved_reports');
              localStorage.setItem('tc_saved_reports', JSON.stringify(tc));
            }
          }

          if (this.pageName === 'orders' && localStorage.getItem('multiFO')) {
            const savedFO = [
              ...JSON.parse(<string>localStorage.getItem('multiFO')),
            ];
            this.allFieldFilters.forEach((f) => {
              savedFO.forEach((fo) => {
                if (f.name === fo.name) {
                  f.value = fo.value;
                  f.operator = fo.operator;
                }
              });
            });
          }

          if (this.pageName !== 'orders' && localStorage.getItem('multiFR')) {
            const savedFR = [
              ...JSON.parse(<string>localStorage.getItem('multiFR')),
            ];
            this.allFieldFilters.forEach((f) => {
              savedFR.forEach((fr) => {
                if (f.name === fr.name) {
                  f.value = fr.value;
                  f.operator = fr.operator;
                }
              });
            });
          }
        },
      });
  }

  getOrders(searchOptions: FilterOptions, isInitial = true) {
    this.resetLoadedStatus();
    this.tableColumnsObj = [];
    this.reorderedColumnsObj = [];
    this.loadingService.changeLoadingStatus(true);
    this.ordersService
      .getOrders(searchOptions)
      .pipe(
        combineLatestWith(this.orderFieldService.getFields()),
        takeUntil(this.destroy)
      )
      .subscribe({
        next: ([resp, dynamicFields]) => {
          this.dynamicFields = dynamicFields;
          this.orders = resp.data.map((order: any) => {
            const fields = [];
            for (let field of dynamicFields) {
              fields.push({
                id: field.id,
                name: field.name,
                type: field.type,
                isCustom: true,
                value: order.dynamicFields.find(
                  (df: OrderDynamicField) => df.dynamicFieldId === field.id
                )?.fieldValueDate
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueDate
                  : order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    )?.fieldValueStr
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueStr
                  : order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    )?.fieldValueNum
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueNum
                  : '',
              });
            }
            for (let f of fields) {
              order = {
                ...order,
                [f.name]: f,
              };
            }

            return order;
          });

          for (let key in this.orders[0]) {
            if (
              key !== 'coldStorageId' &&
              key !== 'consigneeId' &&
              key !== 'customerId' &&
              key !== 'id' &&
              key !== 'isNew' &&
              key !== 'dynamicFields' &&
              key !== 'stage1ApprovedOn' &&
              key !== 'stage2AApprovedOn' &&
              key !== 'stage2BApprovedOn' &&
              key !== 'stage1NotifiedOn' &&
              key !== 'stage2ANotifiedOn' &&
              key !== 'stage2BNotifiedOn' &&
              key !== 'stage3ApprovedOn' &&
              key !== 'stage4ApprovedOn' &&
              key !== 'isCompleted' &&
              key !== 'stage3CmpInventoryApprovedOn' &&
              key !== 'stage3CustomerApprovedOn'
            ) {
              this.tableColumnsObj.push({
                columnDef: key,
                header:
                  key === 'poNumber'
                    ? 'p.o. no.'
                    : key === 'orderNumber'
                    ? 'order no.'
                    : key === 'customerName'
                    ? 'customer'
                    : key === 'consigneeName'
                    ? 'consignee'
                    : key === 'coldStorageName'
                    ? 'cold storage'
                    : key === 'countryId'
                    ? 'destination country'
                    : key === 'createdOn'
                    ? 'created'
                    : key === 'lastModifiedOn'
                    ? 'modified'
                    : key,
                isShow: true,
                isShowBackup: true,
                isCustom: this.orders[0][key]?.name ? true : false,
                fieldId: this.orders[0][key]?.id
                  ? this.orders[0][key]?.id
                  : null,
                type:
                  this.orders[0][key]?.type !== undefined
                    ? this.orders[0][key]?.type
                    : null,
                filterKey:
                  key === 'customerName'
                    ? 'customer.name'
                    : key === 'consigneeName'
                    ? 'consignee.name'
                    : key === 'coldStorageName'
                    ? 'coldStorage.name'
                    : key,
                cell: (order: Order) =>
                  `${this.nullTransform.transform(order[key as keyof Order])}`,
                cell_alt: (order: Order) =>
                  `${order[key as keyof Order].value}`,
                cell_id: (order: Order) =>
                  `${
                    this.countriesService.countriesArray.find(
                      (c) => c.id === order[key as keyof Order]
                    )?.name
                  }`,
              });

              this.reorderedColumnsObj.push({
                columnDef: key,
                header:
                  key === 'poNumber'
                    ? 'p.o. no.'
                    : key === 'orderNumber'
                    ? 'order no.'
                    : key === 'customerName'
                    ? 'customer'
                    : key === 'consigneeName'
                    ? 'consignee'
                    : key === 'coldStorageName'
                    ? 'cold storage'
                    : key === 'countryId'
                    ? 'destination country'
                    : key === 'createdOn'
                    ? 'created'
                    : key === 'lastModifiedOn'
                    ? 'modified'
                    : key,
                isShow: true,
                isShowBackup: true,
                isCustom: this.orders[0][key]?.name ? true : false,
                fieldId: this.orders[0][key]?.id
                  ? this.orders[0][key]?.id
                  : null,
                type:
                  this.orders[0][key]?.type !== undefined
                    ? this.orders[0][key]?.type
                    : null,
                filterKey:
                  key === 'customerName'
                    ? 'customer.name'
                    : key === 'consigneeName'
                    ? 'consignee.name'
                    : key === 'coldStorageName'
                    ? 'coldStorage.name'
                    : key,
                cell: (order: Order) =>
                  `${this.nullTransform.transform(order[key as keyof Order])}`,
                cell_alt: (order: Order) =>
                  `${order[key as keyof Order].value}`,
                cell_id: (order: Order) =>
                  `${
                    this.countriesService.countriesArray.find(
                      (c) => c.id === order[key as keyof Order]
                    )?.name
                  }`,
              });
            }
          }

          if (isInitial) {
            this.applyFilters();
          }

          this.displayedColumns = this.tableColumnsObj.map((c) => c.columnDef);
          const { data, ...clone } = resp;
          this.paginationData = clone;
          this.dataSource = new MatTableDataSource(this.orders);
          this.selectedFilter = '';
          this.isOrdersLoaded = true;
          this.loadingService.changeLoadingStatus(false);
        },
        error: () => {
          this.selectedFilter = '';
          this.isOrdersLoaded = true;
          this.loadingService.changeLoadingStatus(false);
        },
      });
  }

  resetLoadedStatus() {
    this.isOrdersLoaded = false;
  }

  filterByStatus(selector: string, status?: OrderStatus) {
    const selectedBtn = document.querySelector(`.${selector}-orders`);
    selectedBtn?.classList.add('active-filter');
    const btnSiblings = selectedBtn?.parentNode?.childNodes;
    btnSiblings?.forEach((s) => {
      if (s !== selectedBtn) {
        (s as HTMLElement).classList.remove('active-filter');
      }
    });

    this.multiFilters = this.allFieldFilters
      .map((f) => {
        if (f.operator === 'Any') {
          return {
            logic: 'and',
            filters: [
              {
                field: f.field,
                operator: FilterOperator.NotEqual,
                value: null,
              },
              {
                field: f.field,
                operator: FilterOperator.NotEqual,
                value: 0,
              },
            ],
          };
        }

        if (
          (f.name === 'createdOn' || f.name === 'lastModifiedOn') &&
          f.operator === 'Equals' &&
          f.value
        ) {
          return {
            logic: 'and',
            filters: [
              {
                field: f.field,
                operator: FilterOperator.GreaterThanOrEqual,
                value:
                  moment(f.value).startOf('day').format('YYYY-MM-DDTHH:mm:ss') +
                  '.001Z',
              },
              {
                field: f.field,
                operator: FilterOperator.LessThanOrEqual,
                value:
                  moment(f.value).endOf('day').format('YYYY-MM-DDTHH:mm:ss') +
                  '.999Z',
              },
            ],
          };
        }

        if (
          (f.name === 'createdOn' || f.name === 'lastModifiedOn') &&
          f.operator === '!Equals' &&
          f.value
        ) {
          return {
            logic: 'or',
            filters: [
              {
                field: f.field,
                operator: FilterOperator.LessThan,
                value:
                  moment(f.value).startOf('day').format('YYYY-MM-DDTHH:mm:ss') +
                  '.001Z',
              },
              {
                field: f.field,
                operator: FilterOperator.GreaterThan,
                value:
                  moment(f.value).endOf('day').format('YYYY-MM-DDTHH:mm:ss') +
                  '.999Z',
              },
            ],
          };
        }

        return {
          field: f.field,
          operator: f.operator,
          value: f.value,
        };
      })
      .filter((f) => f?.value !== '');

    if (status !== undefined) {
      this.statusFilter = {
        field: 'status',
        operator: FilterOperator.Equals,
        value: status?.toString(),
      };
      this.searchOptions.advancedFilter = {
        logic: 'and',
        filters:
          this.rangeFilter && this.multiFilters.length
            ? [this.statusFilter, ...this.multiFilters, ...this.rangeFilter]
            : !this.rangeFilter && this.multiFilters.length
            ? [this.statusFilter, ...this.multiFilters]
            : this.rangeFilter && !this.multiFilters.length
            ? [this.statusFilter, ...this.rangeFilter]
            : [this.statusFilter],
      };
    } else {
      this.statusFilter = null;
      if (this.rangeFilter) {
        this.searchOptions.advancedFilter = {
          logic: 'and',
          filters: this.multiFilters.length
            ? [...this.multiFilters, ...this.rangeFilter]
            : [...this.rangeFilter],
        };
      } else {
        if (this.multiFilters.length) {
          this.searchOptions.advancedFilter = {
            logic: 'and',
            filters: [...this.multiFilters],
          };
        } else {
          this.searchOptions.advancedFilter = null;
        }
      }
    }
    this.getOrders(this.searchOptions);
  }

  getPageSettings(pageSettings: { perPage: number; currentPage: number }) {
    this.searchOptions.pageSize = pageSettings.perPage;
    this.searchOptions.pageNumber = pageSettings.currentPage;
    this.getOrders(this.searchOptions, false);
  }

  searchOrder() {
    this.search.next(this.searchString.toLowerCase());
  }

  sortOrders(
    field: string,
    isCustom: boolean | undefined,
    type: FieldType | undefined,
    id: string | undefined
  ) {
    if (isCustom) {
      return;
    }
    if (field === 'countryId') {
      field = 'country.name';
    }
    if (!this.isOrdersLoaded) {
      return;
    }
    if (field !== this.selectedSort.field) {
      if (isCustom) {
        this.selectedSort = { field, mode: 'asc' };
        const ft =
          type === 0 || type === 3
            ? 'FieldValueStr'
            : type === 1
            ? 'FieldValueNum'
            : 'FieldValueDate';
        this.searchOptions.orderBy = [
          `DynamicFields.Where(q => q.DynamicFieldId == \"${id}\").Select(s => s.${ft}).FirstOrDefault()`,
        ];
        this.getOrders(this.searchOptions);
        return;
      }
      this.selectedSort = { field, mode: 'asc' };
      if (field === 'stage') {
        this.searchOptions.orderBy = ['Stage', 'IsCompleted'];
      } else {
        this.searchOptions.orderBy = [`${this.selectedSort.field}`];
      }
      this.getOrders(this.searchOptions);
      return;
    }
    if (this.selectedSort.mode === 'asc') {
      if (isCustom) {
        this.selectedSort = { field, mode: 'desc' };
        const ft =
          type === 0
            ? 'FieldValueStr'
            : type === 1
            ? 'FieldValueNum'
            : 'FieldValueDate';
        this.searchOptions.orderBy = [
          `DynamicFields.Where(q => q.DynamicFieldId == \"${id}\").Select(s => s.${ft}).FirstOrDefault() desc`,
        ];
        this.getOrders(this.searchOptions);
        return;
      }
      this.selectedSort = { field, mode: 'desc' };
      if (field === 'stage') {
        this.searchOptions.orderBy = [
          `Stage ${this.selectedSort.mode}`,
          `IsCompleted ${this.selectedSort.mode}`,
        ];
      } else {
        this.searchOptions.orderBy = [
          `${this.selectedSort.field} ${this.selectedSort.mode}`,
        ];
      }

      this.getOrders(this.searchOptions);
      return;
    }
    if (this.selectedSort.mode === 'desc') {
      this.selectedSort = { field: '', mode: '' };
      this.searchOptions.orderBy = [];
      this.getOrders(this.searchOptions);
      return;
    }
  }

  selectCol(col: string) {
    if (['stage', 'status'].includes(col)) {
      this.filteredFieldType = FieldType.List;
    }

    if (['poNumber'].includes(col)) {
      this.filteredFieldType = FieldType.Number;
    }

    if (['consigneeName', 'customerName', 'coldStorageName'].includes(col)) {
      this.filteredFieldType = FieldType.Text;
    }

    if (['createdOn', 'lastModifiedOn'].includes(col)) {
      this.filteredFieldType = FieldType.DateTime;
    }

    if (
      ![
        'poNumber',
        'stage',
        'createdOn',
        'lastModifiedOn',
        'status',
        'consigneeName',
        'customerName',
        'coldStorageName',
      ].includes(col)
    ) {
      this.filteredFieldType = <FieldType>(
        this.dynamicFields.find(
          (f: OrderField) => f.name.toLowerCase() === col.toLowerCase()
        )?.type
      );
    }
    this.selectedCol = col;
  }

  toggleFilterOptions() {
    this.showFilterModal = !this.showFilterModal;
    setTimeout(() => {
      document
        .getElementById(this.selectedCol)
        ?.firstElementChild?.classList.add('active-li');
    }, 100);
  }

  dragItem(e: DragEvent, el: string) {
    const elPos = this.reorderedColumns.indexOf(el);
    const elState = this.columnsStatesBackup[elPos];
    const dataForTransfer = { element: el, elementState: elState };
    e.dataTransfer?.setData('text/plain', JSON.stringify(dataForTransfer));
  }

  dropItem(e: DragEvent) {
    const transferedData = JSON.parse(e.dataTransfer?.getData('text/plain')!);
    const dropEl = transferedData.element;
    const dropElState = transferedData.elementState;
    const dropPos = this.reorderedColumns.indexOf(dropEl);
    const targetId = (e.currentTarget as HTMLElement).id;
    const targetPos = this.reorderedColumns.indexOf(targetId);
    this.reorderedColumns.splice(dropPos, 1);
    this.reorderedColumns.splice(targetPos, 0, dropEl);
    this.columnsStatesBackup.splice(dropPos, 1);
    this.columnsStatesBackup.splice(targetPos, 0, dropElState);
    this.columnsStates = [...this.columnsStatesBackup];
  }

  disableDefaultBehavior(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  showHideColumn(index: number, col: string, event: MatCheckboxChange) {
    this.columnsStatesBackup[index] = !this.columnsStatesBackup[index];

    if (!event.checked) {
      this.allFieldFilters.forEach((f) => {
        if (f.name === col) {
          f.isShow = false;
          f.value = '';
          f.operator = '';
        }
      });
    } else {
      this.allFieldFilters.forEach((f) => {
        if (f.name === col) {
          f.isShow = true;
        }
      });
    }
  }

  resetMultiFilters() {
    this.allFieldFilters.forEach((f) => {
      f.value = '';
      f.operator = 'Equals';
    });
    this.multiFilters = null;
    this.isApplyFilters = true;
    this.applyMultiFilters();
  }

  applyFilters() {
    this.tableColumns = [...this.reorderedColumns];
    this.columnsStates = [...this.columnsStatesBackup];

    let hiddenColumns: string[] = [];

    this.columnsStates.forEach((s, i) => {
      if (!s) {
        hiddenColumns.push(this.tableColumns[i]);
      }
    });

    this.tableColumns = this.tableColumns.filter(
      (t) => !hiddenColumns.includes(t)
    );

    this.tableColumnsObj = this.reorderedColumnsObj.filter(
      (t) => !hiddenColumns.includes(t.columnDef)
    );

    this.tableColumnsObj.sort(
      (a: OrderTableColumn, b: OrderTableColumn) =>
        this.tableColumns.indexOf(a.columnDef) -
        this.tableColumns.indexOf(b.columnDef)
    );

    this.displayedColumns = this.tableColumnsObj.map((c) => c.columnDef);

    const tableSettings = {
      tableColumns: this.tableColumns,
      reorderedColumns: this.reorderedColumns,
      columnsStates: this.columnsStates,
    };
    if (this.pageName === 'orders') {
      localStorage.setItem('table', JSON.stringify(tableSettings));
    } else {
      localStorage.setItem('reports_table', JSON.stringify(tableSettings));
    }

    setTimeout(() => {
      this.allFieldFilters.forEach((f) => {
        if (!this.tableColumns.includes(f.name)) {
          f.isShow = false;
        }
      });
    }, 1500);
  }

  applyMultiFilters() {
    setTimeout(() => {
      if (this.pageName === 'orders') {
        localStorage.removeItem('multiFO');
        localStorage.setItem('multiFO', JSON.stringify(this.allFieldFilters));
      } else {
        localStorage.removeItem('multiFR');
        localStorage.setItem('multiFR', JSON.stringify(this.allFieldFilters));
      }

      this.multiFilters = this.allFieldFilters
        .map((f) => {
          if (f.operator === 'Any') {
            return {
              logic: 'and',
              filters: [
                {
                  field: f.field,
                  operator: FilterOperator.NotEqual,
                  value: null,
                },
                {
                  field: f.field,
                  operator: FilterOperator.NotEqual,
                  value: 0,
                },
              ],
            };
          }

          if (
            (f.name === 'createdOn' || f.name === 'lastModifiedOn') &&
            f.operator === 'Equals' &&
            f.value
          ) {
            return {
              logic: 'and',
              filters: [
                {
                  field: f.field,
                  operator: FilterOperator.GreaterThanOrEqual,
                  value:
                    moment(f.value)
                      .startOf('day')
                      .format('YYYY-MM-DDTHH:mm:ss') + '.001Z',
                },
                {
                  field: f.field,
                  operator: FilterOperator.LessThanOrEqual,
                  value:
                    moment(f.value).endOf('day').format('YYYY-MM-DDTHH:mm:ss') +
                    '.999Z',
                },
              ],
            };
          }

          if (
            (f.name === 'createdOn' || f.name === 'lastModifiedOn') &&
            f.operator === '!Equals' &&
            f.value
          ) {
            return {
              logic: 'or',
              filters: [
                {
                  field: f.field,
                  operator: FilterOperator.LessThan,
                  value:
                    moment(f.value)
                      .startOf('day')
                      .format('YYYY-MM-DDTHH:mm:ss') + '.001Z',
                },
                {
                  field: f.field,
                  operator: FilterOperator.GreaterThan,
                  value:
                    moment(f.value).endOf('day').format('YYYY-MM-DDTHH:mm:ss') +
                    '.999Z',
                },
              ],
            };
          }

          return {
            field: f.field,
            operator: f.operator,
            value: f.value,
          };
        })
        .filter((f) => f?.value !== '' && f.operator !== '');

      if (this.multiFilters.length) {
        this.searchOptions.pageNumber = 1;
        this.searchOptions.advancedFilter = {
          logic: 'and',
          filters:
            this.statusFilter && this.rangeFilter
              ? [this.statusFilter, ...this.multiFilters, ...this.rangeFilter]
              : this.statusFilter && !this.rangeFilter
              ? [this.statusFilter, ...this.multiFilters]
              : !this.statusFilter && this.rangeFilter
              ? [...this.multiFilters, ...this.rangeFilter]
              : [...this.multiFilters],
        };
        this.getSummary(this.orderStatus.Open, this.multiFilters);
        this.getSummary(this.orderStatus.Closed, this.multiFilters);
        this.getSummary(this.orderStatus.Canceled, this.multiFilters);
      } else {
        this.searchOptions.pageNumber = 1;
        this.searchOptions.advancedFilter = {
          logic: 'and',
          filters:
            this.statusFilter && this.rangeFilter
              ? [this.statusFilter, ...this.rangeFilter]
              : this.statusFilter && !this.rangeFilter
              ? [this.statusFilter]
              : !this.statusFilter && this.rangeFilter
              ? [...this.rangeFilter]
              : [...this.multiFilters],
        };
        this.getSummary(this.orderStatus.Open);
        this.getSummary(this.orderStatus.Closed);
        this.getSummary(this.orderStatus.Canceled);
      }

      this.isApplyFilters = false;
      this.getOrders(this.searchOptions);
    }, 1000);
  }

  cancelFiltering() {
    if (this.pageName === 'orders' && localStorage.getItem('multiFO')) {
      const savedFO = [...JSON.parse(<string>localStorage.getItem('multiFO'))];
      this.allFieldFilters.forEach((f) => {
        savedFO.forEach((fo) => {
          if (f.name === fo.name) {
            f.value = fo.value;
            f.operator = fo.operator;
          }
        });
      });
    }

    if (this.pageName !== 'orders' && localStorage.getItem('multiFR')) {
      const savedFR = [...JSON.parse(<string>localStorage.getItem('multiFR'))];
      this.allFieldFilters.forEach((f) => {
        savedFR.forEach((fr) => {
          if (f.name === fr.name) {
            f.value = fr.value;
            f.operator = fr.operator;
          }
        });
      });
    }
  }

  removeValue(field: any) {
    this.allFieldFilters.forEach((f) => {
      if (f.name === field.name) {
        f.value = '';
        f.operator = '';
      }
    });
  }

  resetFilters() {
    if (this.pageName === 'orders') {
      localStorage.removeItem('table');
    } else {
      localStorage.removeItem('reports_table');
    }
    this.reorderedColumns = [...this.reorderedColumnsB];
    this.columnsStatesBackup = [...this.columnsStatesBackupB];

    this.columnsStates = [...this.columnsStatesBackup];

    let hiddenColumns: string[] = [];

    this.columnsStates.forEach((s, i) => {
      if (!s) {
        hiddenColumns.push(this.tableColumns[i]);
      }
    });

    this.tableColumns = this.tableColumns.filter(
      (t) => !hiddenColumns.includes(t)
    );
    this.displayedColumns = this.tableColumnsObj.map((c) => c.columnDef);
  }

  formatName(name: string) {
    return name.split(/(?=[A-Z])/).join(' ');
  }

  hideModal(event: Event) {
    if (event.target === event.currentTarget) {
      this.cancelFiltering();
      this.showFilterModal = false;
    }
  }

  getAllOrders(pageNumber: number) {
    let searchOpts: FilterOptions = {
      advancedSearch: {
        fields: [],
        keyword: '',
      },
      keyword: '',
      advancedFilter: null,
      pageNumber,
      pageSize: 500,
      orderBy: ['stage desc'],
    };
    this.ordersService
      .getOrders(searchOpts)
      .pipe(
        combineLatestWith(this.orderFieldService.getFields()),
        takeUntil(this.destroy)
      )
      .subscribe({
        next: ([resp, dynamicFields]) => {
          let o = (this.orders = resp.data.map((order: any) => {
            const fields = [];
            for (let field of dynamicFields) {
              fields.push({
                name: field.name,
                type: field.type,
                isCustom: true,
                value: order.dynamicFields.find(
                  (df: OrderDynamicField) => df.dynamicFieldId === field.id
                )?.fieldValueDate
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueDate
                  : order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    )?.fieldValueStr
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueStr
                  : order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    )?.fieldValueNum
                  ? order.dynamicFields.find(
                      (df: OrderDynamicField) => df.dynamicFieldId === field.id
                    ).fieldValueNum
                  : '',
              });
            }
            for (let f of fields) {
              order = {
                ...order,
                [f.name]: f,
              };
            }
            return order;
          }));

          this.ordersForXslx.push(...o);
          this.dataSource = new MatTableDataSource(this.ordersForXslx);

          if (this.ordersForXslx.length < this.paginationData.totalCount) {
            this.getAllOrders(pageNumber + 1);
          } else {
            setTimeout(() => {
              const workSheet: WorkSheet = utils.table_to_sheet(
                document.getElementById('orders-table')
              );
              const workBook: WorkBook = utils.book_new();
              utils.book_append_sheet(workBook, workSheet, 'Orders');
              writeFile(workBook, 'Orders.xlsx');
              this.getOrders(this.searchOptions);
              this.ordersForXslx = [];
              this.loadingService.changeLoadingStatus(false);
              enableBodyScroll(this.exportModalEl.nativeElement);
            }, 2000);
          }
        },
      });
  }

  exportToExcel() {
    this.loadingService.changeLoadingStatus(true);
    setTimeout(() => {
      disableBodyScroll(this.exportModalEl.nativeElement);
      this.getAllOrders(1);
    }, 500);
  }

  goToCustomer(event: Event, id: string) {
    event.stopPropagation();
    this.router.navigate(['dashboard/customers', id]);
  }

  selectLine(lineId: string) {
    const lines = document.querySelectorAll('.li-line');
    const line = document.getElementById(lineId);
    lines.forEach((line) => {
      line.firstElementChild?.classList.remove('active-li');
    });
    line?.firstElementChild?.classList.add('active-li');
  }

  hideCustomField(field: string): boolean {
    return ![
      'poNumber',
      'orderNumber',
      'stage',
      'status',
      'consigneeName',
      'customerName',
      'coldStorageName',
      'countryId',
      'createdOn',
      'lastModifiedOn',
    ].includes(field);
  }

  ngOnDestroy(): void {
    clearAllBodyScrollLocks();
    this.destroy.next(true);
    this.destroy.complete();
  }
}
