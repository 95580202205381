<div class="side-modal modal-el" (click)="backdropClick($event)">
  <mat-icon class="close-side-modal" (click)="closeModal()">close</mat-icon>
  <div class="side-info modal-info-box-alt" #editModal>
    <div class="side-info-head">
      <div class="title-close">
        <span>User Details</span>
      </div>
      <div class="main-info">
        <div class="upper">{{ user.firstName }} {{ user.lastName }}</div>
        <div class="middle">{{ user.companyName }}</div>
        <div class="lower">
          Last activity
          {{
            userLastActivity
              ? (userLastActivity | date : "MM/dd/yyyy hh:mm a")
              : "N/A"
          }}
        </div>
      </div>
    </div>
    <div class="edit-form">
      <form [formGroup]="userForm">
        <div class="double-controls">
          <div class="form-control">
            <label for="firstName">First Name</label>
            <input type="text" id="firstName" formControlName="firstName" />
            <div class="field-error">
              <span
                *ngIf="
                  userForm.controls.firstName?.touched &&
                  userForm.controls.firstName?.hasError('required')
                "
                >First name is required</span
              >
              <span
                *ngIf="
                  userForm.controls.firstName?.touched &&
                  userForm.controls.firstName?.hasError('pattern')
                "
                >Incorrect name</span
              >
            </div>
          </div>
          <div class="form-control">
            <label for="lastName">Last Name</label>
            <input type="text" id="lastName" formControlName="lastName" />
            <div class="field-error">
              <span
                *ngIf="
                  userForm.controls.lastName?.touched &&
                  userForm.controls.lastName?.hasError('required')
                "
                >Last name is required</span
              >
              <span
                *ngIf="
                  userForm.controls.lastName?.touched &&
                  userForm.controls.lastName?.hasError('pattern')
                "
                >Incorrect last name</span
              >
            </div>
          </div>
        </div>
        <div class="form-control phone-control">
          <label for="phoneNumber">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            (input)="validateTel()"
            formControlName="phoneNumber"
          />
        </div>
        <div class="field-error">
          <span
            *ngIf="
              userForm.controls.phoneNumber?.touched &&
              userForm.controls.phoneNumber?.hasError('pattern')
            "
            >Invalid number</span
          >
          <span *ngIf="isTelCustomError">{{ telError }}</span>
          <span
            *ngIf="
              userForm.controls.phoneNumber?.touched &&
              userForm.controls.phoneNumber?.hasError('required')
            "
            >Phone number is required</span
          >
        </div>
        <div class="form-control email-control">
          <label for="email">Email</label>
          <input type="text" id="email" readonly formControlName="email" />
        </div>
        <div class="field-error">
          <span
            *ngIf="
              userForm.controls.email?.touched &&
              userForm.controls.email?.hasError('required')
            "
            >Last name is required</span
          >
          <span
            *ngIf="
              userForm.controls.email?.touched &&
              userForm.controls.email?.hasError('pattern')
            "
            >Incorrect email</span
          >
        </div>
        <div class="form-control role-control">
          <label>Role</label>
          <mat-form-field appearance="outline">
            <mat-select
              [(value)]="selectedRole"
              disableRipple
              (selectionChange)="changeRole()"
            >
              <mat-option *ngFor="let role of availableRoles" [value]="role">{{
                role.name
              }}</mat-option>
            </mat-select>
            <mat-icon matSuffix class="select-icon-replacement"
              >expand_more</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="form-control">
          <mat-checkbox
            formControlName="receiveEmailNotificationWhenOrderDetailsChanged"
            >Notify user of details changes</mat-checkbox
          >
          <mat-checkbox
            formControlName="receiveEmailNotificationWhenOrderStageChanged"
            >Notify user of stage changes</mat-checkbox
          >
          <mat-checkbox
            formControlName="receiveEmailNotificationWhenOrderFileUploaded"
            >Notify user of file upload</mat-checkbox
          >
        </div>
      </form>
    </div>
    <div class="reset-pass" (click)="forgotPassword()">Reset Password</div>
    <div class="acc-del">
      <div class="del-header">Account deletion</div>
      <div class="del-help">
        You will be asked to confirm the account deletion. After the process
        will be finished an employee couldn’t use his personal credentials to
        log in.
      </div>
      <div class="del-acc-btn" (click)="deleteAccount()">Delete Account</div>
    </div>
    <div class="side-actions form-actions">
      <button mat-stroked-button class="cancel-btn" (click)="closeModal()">
        Cancel
      </button>
      <button
        mat-button
        class="action-btn"
        [disabled]="userForm.invalid || isTelCustomError"
        (click)="editUser()"
        *ngIf="!isRoleChanged"
      >
        Save
      </button>
      <button
        mat-button
        class="action-btn"
        (click)="editUserRole()"
        *ngIf="isRoleChanged"
      >
        Update Role
      </button>
    </div>
  </div>
</div>
