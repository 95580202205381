import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @Output() modalState = new EventEmitter<{
    isDelete: boolean;
    isUpdate: boolean;
  }>();

  constructor(
    private userService: UserService,
    private toastr: ToastrService
  ) {}
  private destroy = new Subject<boolean>();

  ngOnInit(): void {}

  deleteUser() {
    this.userService
      .deleteUser(this.user.id)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: () => {
          this.modalState.emit({ isDelete: true, isUpdate: true });
          this.toastr.success('User successfully deleted', 'Success');
          this.closeModal();
        },
        error: (err) => {
          this.closeModal();
        },
      });
  }

  closeModal() {
    this.modalState.emit({ isDelete: false, isUpdate: false });
  }

  backdropClick(event: Event) {
    if (event.target === event.currentTarget) {
      this.modalState.emit({ isDelete: false, isUpdate: false });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
