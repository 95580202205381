import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFilter',
  pure: false,
})
export class DateFilterPipe implements PipeTransform {
  transform(items: any[], filter: string): any {
    const today = new Date(
      new Date().setDate(new Date().getDate() + 0)
    ).setHours(0, 0, 0, 0);
    const tomorrow = new Date(
      new Date().setDate(new Date().getDate() + 1)
    ).setHours(0, 0, 0, 0);
    const yesterday = new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).setHours(0, 0, 0, 0);
    const week = new Date(
      new Date().setDate(new Date().getDate() - 7)
    ).setHours(0, 0, 0, 0);
    const month = new Date(
      new Date().setDate(new Date().getDate() - 31)
    ).setHours(0, 0, 0, 0);
    const year = new Date(
      new Date().setMonth(new Date().getMonth() - 12)
    ).setHours(0, 0, 0, 0);

    items.sort(
      (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
    );

    if (!filter) {
      return items;
    }

    switch (filter) {
      case 'Today':
        return items.filter(
          (item) => new Date(item.dateTime).setHours(0, 0, 0, 0) === today
        );
      case 'Yesterday':
        return items.filter(
          (item) => new Date(item.dateTime).setHours(0, 0, 0, 0) === yesterday
        );
      case 'LastWeek':
        return items.filter(
          (item) =>
            new Date(item.dateTime).setHours(0, 0, 0, 0) < yesterday &&
            new Date(item.dateTime).setHours(0, 0, 0, 0) >= week
        );
      case 'MoreThanAWeek':
        return items.filter(
          (item) =>
            new Date(item.dateTime).setHours(0, 0, 0, 0) < week &&
            new Date(item.dateTime).setHours(0, 0, 0, 0) >= month
        );
      case 'MoreThanAMonth':
        return items.filter(
          (item) =>
            new Date(item.dateTime).setHours(0, 0, 0, 0) < month &&
            new Date(item.dateTime).setHours(0, 0, 0, 0) >= year
        );
      case 'MoreThanAYearAgo':
        return items.filter(
          (item) => new Date(item.dateTime).setHours(0, 0, 0, 0) < year
        );
    }
  }
}
