<div class="not-found-wrapper">
  <div class="not-found-container">
    <div class="code-box">404</div>
    <div class="not-found-box">Page Not Found</div>
    <div class="page-hint">
      Looks like the page you were looking for is no longer here
    </div>
    <a *ngIf="!token" routerLink="">Go Back</a>
  </div>
</div>
