<div class="page-wrapper" *ngIf="customer">
  <div class="title-box">
    <div class="title" *ngIf="!isAdmin">
      Consignees
      <div class="title-count" *ngIf="paginationData">
        {{ paginationData.totalCount }}
        <span *ngIf="paginationData.totalCount !== 1">consignees</span>
        <span *ngIf="paginationData.totalCount === 1">consignee</span>
      </div>
    </div>

    <div class="date-select" *ngIf="!isAdmin">
      <app-date-range-filter
        (selectedRange)="getRange($event)"
      ></app-date-range-filter>
    </div>
  </div>
  <div class="search-box">
    <div class="form-control">
      <div class="icon">
        <img src="/assets/img/icons/search.svg" alt="search" />
      </div>
      <input
        type="text"
        placeholder="Search..."
        (input)="searchConsignee()"
        [(ngModel)]="searchString"
      />
    </div>
    <div class="display-options">
      <div
        class="disp-btn display-all"
        [ngClass]="{ 'active-disp-btn': checkDisplaySelection() }"
        (click)="selectColumns()"
      >
        Display All
      </div>
      <div
        class="disp-btn display-filters"
        [ngClass]="{ 'active-disp-btn': !checkDisplaySelection() }"
        [matMenuTriggerFor]="filterOptions"
      >
        <div class="btn-title">
          <span>Display</span>
          <mat-icon>expand_more</mat-icon>
        </div>
        <mat-menu #filterOptions="matMenu">
          <div class="filter-options">
            <div
              class="f-option"
              *ngFor="let col of tableFilterColumns; let i = index"
            >
              <mat-checkbox
                (click)="$event.stopPropagation()"
                [disabled]="lastColumnIndex === i"
                [(ngModel)]="columnsStates[i]"
                (change)="selectColumns(i)"
                >{{ col }}</mat-checkbox
              >
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <button mat-button class="add-btn" (click)="toggleConsigneeModal()">
      Add New Consignee
    </button>
  </div>

  <div class="table-box">
    <table
      mat-table
      [dataSource]="dataSource"
      class="data-table consignees-table"
    >
      <ng-container matColumnDef="company">
        <th
          mat-header-cell
          class="sortable-header"
          *matHeaderCellDef
          (click)="sortConsignees('name')"
        >
          <div class="table-header-wrapper">
            COMPANY
            <div class="sort-controls" *ngIf="selectedSort.field === 'name'">
              <img
                src="/assets/img/icons/sort_arrow_up.svg"
                alt="up"
                *ngIf="selectedSort.mode !== 'desc'"
              />
              <img
                src="/assets/img/icons/sort_arrow_down.svg"
                alt="down"
                *ngIf="selectedSort.mode !== 'asc'"
              />
            </div>
            <div class="sort-controls" *ngIf="selectedSort.field !== 'name'">
              <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
              <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
            </div>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let consignee"
          class="customer-name"
          (click)="selectConsignee(consignee); toggleConsigneeModal()"
        >
          <div class="company-info" [title]="consignee.name">
            {{ consignee.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="person">
        <th
          mat-header-cell
          class="sortable-header"
          *matHeaderCellDef
          (click)="sortConsignees('lastName')"
        >
          <div class="table-header-wrapper">
            PERSON
            <div
              class="sort-controls"
              *ngIf="selectedSort.field === 'lastName'"
            >
              <img
                src="/assets/img/icons/sort_arrow_up.svg"
                alt="up"
                *ngIf="selectedSort.mode !== 'desc'"
              />
              <img
                src="/assets/img/icons/sort_arrow_down.svg"
                alt="down"
                *ngIf="selectedSort.mode !== 'asc'"
              />
            </div>
            <div
              class="sort-controls"
              *ngIf="selectedSort.field !== 'lastName'"
            >
              <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
              <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
            </div>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let consignee"
          class="users"
          (click)="selectConsignee(consignee); toggleConsigneeModal()"
        >
          <div class="user-name">
            {{ consignee.firstName }} {{ consignee.lastName }}
          </div>
          <div class="user-email">
            {{ consignee.email }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th
          mat-header-cell
          class="sortable-header"
          *matHeaderCellDef
          (click)="sortConsignees('phone')"
        >
          <div class="table-header-wrapper">
            PHONE
            <div class="sort-controls" *ngIf="selectedSort.field === 'phone'">
              <img
                src="/assets/img/icons/sort_arrow_up.svg"
                alt="up"
                *ngIf="selectedSort.mode !== 'desc'"
              />
              <img
                src="/assets/img/icons/sort_arrow_down.svg"
                alt="down"
                *ngIf="selectedSort.mode !== 'asc'"
              />
            </div>
            <div class="sort-controls" *ngIf="selectedSort.field !== 'phone'">
              <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
              <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
            </div>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let consignee"
          class="consignee-phone"
          (click)="selectConsignee(consignee); toggleConsigneeModal()"
        >
          <div class="phone">
            {{ consignee.phone }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="destination country">
        <th
          mat-header-cell
          class="sortable-header"
          *matHeaderCellDef
          (click)="sortConsignees('country.name')"
        >
          <div class="table-header-wrapper">
            DESTINATION COUNTRY
            <div
              class="sort-controls"
              *ngIf="selectedSort.field === 'country.name'"
            >
              <img
                src="/assets/img/icons/sort_arrow_up.svg"
                alt="up"
                *ngIf="selectedSort.mode !== 'desc'"
              />
              <img
                src="/assets/img/icons/sort_arrow_down.svg"
                alt="down"
                *ngIf="selectedSort.mode !== 'asc'"
              />
            </div>
            <div
              class="sort-controls"
              *ngIf="selectedSort.field !== 'country.name'"
            >
              <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
              <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
            </div>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let consignee"
          class="dest-country"
          (click)="selectConsignee(consignee); toggleConsigneeModal()"
        >
          {{ consignee.countryId.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="destination address">
        <th
          mat-header-cell
          class="sortable-header"
          *matHeaderCellDef
          (click)="sortConsignees('address')"
        >
          <div class="table-header-wrapper">
            DESTINATION ADDRESS
            <div class="sort-controls" *ngIf="selectedSort.field === 'address'">
              <img
                src="/assets/img/icons/sort_arrow_up.svg"
                alt="up"
                *ngIf="selectedSort.mode !== 'desc'"
              />
              <img
                src="/assets/img/icons/sort_arrow_down.svg"
                alt="down"
                *ngIf="selectedSort.mode !== 'asc'"
              />
            </div>
            <div class="sort-controls" *ngIf="selectedSort.field !== 'address'">
              <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
              <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
            </div>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let consignee"
          class="dest-address"
          (click)="selectConsignee(consignee); toggleConsigneeModal()"
        >
          {{ consignee.address }}
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th
          mat-header-cell
          class="sortable-header"
          *matHeaderCellDef
          (click)="sortConsignees('countOfOrders')"
        >
          <div class="table-header-wrapper">
            TOTAL
            <div
              class="sort-controls"
              *ngIf="selectedSort.field === 'countOfOrders'"
            >
              <img
                src="/assets/img/icons/sort_arrow_up.svg"
                alt="up"
                *ngIf="selectedSort.mode !== 'desc'"
              />
              <img
                src="/assets/img/icons/sort_arrow_down.svg"
                alt="down"
                *ngIf="selectedSort.mode !== 'asc'"
              />
            </div>
            <div
              class="sort-controls"
              *ngIf="selectedSort.field !== 'countOfOrders'"
            >
              <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
              <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let consignee" class="total">
          <div
            class="count-of-orders"
            [matMenuTriggerFor]="ordersList"
            #tableTrigger="matMenuTrigger"
          >
            {{ consignee.countOfOrders }}
          </div>
          <div class="orders-list">
            <mat-menu #ordersList="matMenu">
              <app-consignee-orders-modal
                *ngIf="tableTrigger.menuOpen"
                (click)="$event.stopPropagation()"
                [consigneeId]="consignee.id ?? ''"
              ></app-consignee-orders-modal>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>

      <tr class="mat-row no-data-row" *matNoDataRow>
        <td class="mat-cell no-data-cell" colspan="7">
          No data matching the filter
        </td>
      </tr>
    </table>
  </div>
  <app-paginator
    [records]="paginationData"
    (pageSettings)="getPageSettings($event)"
  ></app-paginator>
</div>

<app-edit-consignee
  [consigneeForEditId]="selectedConsignee?.id"
  [companyId]="customer.id"
  (modalState)="toggleConsigneeModal(); updateState($event)"
  *ngIf="isNewConsignee"
></app-edit-consignee>
