import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from 'src/app/models/customer.model';
import { ConsigneeService } from 'src/app/services/consignee.service';
import { CountriesService } from 'src/app/services/countries.service';
import { Consignee } from 'src/app/models/consignee.model';
import { FilterOptions } from 'src/app/models/filter.model';
import { PaginationData } from 'src/app/models/helpers.model';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  takeUntil,
  combineLatestWith,
} from 'rxjs';
import { Country } from 'src/app/models/country.model';
import { FilterOperator } from 'src/app/enums/filter-operator';
@Component({
  selector: 'app-customer-consignees',
  templateUrl: './customer-consignees.component.html',
  styleUrls: ['./customer-consignees.component.scss'],
})
export class CustomerConsigneesComponent implements OnInit, OnDestroy {
  @Input() customer!: Company;
  @Input() isAdmin!: boolean;
  consignees!: Consignee[];
  tableColumns = [
    'company',
    'person',
    'phone',
    'destination country',
    'destination address',
    'total',
  ];
  tableFilterColumns = [
    'company',
    'person',
    'phone',
    'destination country',
    'destination address',
    'total',
  ];
  columnsStates = [true, true, true, true, true, true, true];
  columnsStatesBackup = [true, true, true, true, true, true, true];
  paginationData!: PaginationData;
  searchOptions: FilterOptions = {
    advancedSearch: {
      fields: [
        'name',
        'phone',
        'firstName',
        'lastNAme',
        'email',
        'address',
        'country.name',
      ],
      keyword: '',
    },
    keyword: '',
    advancedFilter: null,
    pageNumber: 1,
    pageSize: 15,
    orderBy: [],
  };
  rangeFilter!: Array<{
    field: string;
    operator: string;
    value: string;
  }> | null;
  customerFilter!: {
    field: string;
    operator: string;
    value: string;
  };
  searchString = '';
  isNewConsignee = false;
  lastColumnIndex!: number | null;
  dataSource: any;
  selectedConsignee!: Consignee | null;
  selectedSort = { field: '', mode: '' };
  private destroy = new Subject<boolean>();
  private search = new Subject<string>();

  constructor(
    private consigneeService: ConsigneeService,
    private countriesService: CountriesService
  ) {}

  ngOnInit(): void {
    if (this.isAdmin) {
      this.customerFilter = {
        field: 'companyId',
        operator: FilterOperator.Equals,
        value: this.customer.id,
      };
      this.searchOptions.advancedFilter = {
        logic: 'and',
        filters: [this.customerFilter],
      };
      this.getConsignees(this.searchOptions);
    }
    this.search.pipe(debounceTime(2000), distinctUntilChanged()).subscribe({
      next: () => {
        this.searchOptions.advancedSearch.keyword =
          this.searchString.toLowerCase();
        this.searchOptions.pageNumber = 1;
        this.getConsignees(this.searchOptions);
      },
    });
  }

  getConsignees(searchOptions: FilterOptions) {
    this.consigneeService
      .getConsignees(searchOptions)
      .pipe(
        combineLatestWith(this.countriesService.contriesList),
        takeUntil(this.destroy)
      )
      .subscribe({
        next: ([considneesResp, countries]) => {
          if (countries) {
            this.consignees = considneesResp.data.map(
              (consignee: Consignee) => {
                consignee.countryId = countries.find(
                  (country: Country) => country.id === consignee.countryId
                );
                return consignee;
              }
            );
          }
          const { data, ...clone } = considneesResp;
          this.paginationData = clone;
          this.dataSource = new MatTableDataSource(this.consignees);
        },
      });
  }

  getRange(range: { gte: string; lte: string }) {
    if (range.gte) {
      this.rangeFilter = [
        {
          field: 'createdOn',
          operator: 'gte',
          value: range.gte,
        },
        {
          field: 'createdOn',
          operator: 'lte',
          value: range.lte,
        },
      ];
      this.searchOptions.advancedFilter = {
        logic: 'and',
        filters: [...this.rangeFilter],
      };
    } else {
      this.searchOptions.advancedFilter = null;
    }
    this.getConsignees(this.searchOptions);
  }

  selectConsignee(data: Consignee) {
    this.selectedConsignee = { ...data };
  }

  searchConsignee() {
    this.search.next(this.searchString.toLowerCase());
  }

  sortConsignees(field: string) {
    if (field !== this.selectedSort.field) {
      this.selectedSort = { field, mode: 'asc' };
      this.searchOptions.orderBy = [`${this.selectedSort.field}`];
      this.getConsignees(this.searchOptions);
      return;
    }
    if (this.selectedSort.mode === 'asc') {
      this.selectedSort = { field, mode: 'desc' };
      this.searchOptions.orderBy = [
        `${this.selectedSort.field} ${this.selectedSort.mode}`,
      ];
      this.getConsignees(this.searchOptions);
      return;
    }
    if (this.selectedSort.mode === 'desc') {
      this.selectedSort = { field: '', mode: '' };
      this.searchOptions.orderBy = [];
      this.getConsignees(this.searchOptions);
      return;
    }
  }

  toggleConsigneeModal() {
    this.isNewConsignee = !this.isNewConsignee;
  }

  getPageSettings(pageSettings: { perPage: number; currentPage: number }) {
    this.searchOptions.pageSize = pageSettings.perPage;
    this.searchOptions.pageNumber = pageSettings.currentPage;
    this.getConsignees(this.searchOptions);
  }

  selectColumns(index?: number) {
    this.tableColumns = [...this.tableFilterColumns];
    if (index !== undefined) {
      this.columnsStatesBackup[index] = !this.columnsStatesBackup[index];
    } else {
      this.columnsStatesBackup = [true, true, true, true, true, true, true];
      this.columnsStates = [true, true, true, true, true, true, true];
    }
    let hiddenColumns: string[] = [];

    this.columnsStatesBackup.forEach((s, i) => {
      if (!s) {
        hiddenColumns.push(this.tableColumns[i]);
      }
    });

    this.tableColumns = this.tableColumns.filter(
      (t) => !hiddenColumns.includes(t)
    );

    if (this.tableColumns.length === 1) {
      this.lastColumnIndex = this.tableFilterColumns.indexOf(
        this.tableColumns[0]
      );
    } else {
      this.lastColumnIndex = null;
    }
  }

  updateState(data: { isDelete: boolean; isUpdate: boolean }) {
    this.selectedConsignee = null;

    if (data.isUpdate) {
      this.getConsignees(this.searchOptions);
      this.isNewConsignee = false;
    }
  }

  checkDisplaySelection(): boolean {
    return this.columnsStates.every((cs) => cs === true);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
