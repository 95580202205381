import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PaginationData } from 'src/app/models/helpers.model';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() records: PaginationData = {
    currentPage: 1,
    hasNextPage: true,
    hasPreviousPage: false,
    pageSize: 1,
    totalCount: 1,
    totalPages: 1,
  };
  @Output() pageSettings = new EventEmitter<{
    perPage: number;
    currentPage: number;
  }>();

  numPages!: number;
  perPage = 15;
  currentPage = 1;
  totalRecords!: number;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.records = changes['records'].currentValue;
    if (this.records && this.records.totalCount) {
      this.totalRecords = this.records.totalCount;
    }

    this.currentPage = this.records?.currentPage;
    this.perPage = this.records?.pageSize;
    this.numPages = Math.ceil(this.records?.totalCount / this.perPage);
  }

  ngOnInit(): void {}

  selectPage(page: number) {
    this.currentPage = page;
    this.pageSettings.emit({
      perPage: this.perPage,
      currentPage: this.currentPage,
    });
  }

  selectPerPage(n: number) {
    this.perPage = n;
    this.numPages = Math.ceil(this.records.totalCount / this.perPage);
    this.pageSettings.emit({
      perPage: this.perPage,
      currentPage: 1,
    });
  }
}
