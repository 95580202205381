import { Pipe, PipeTransform } from '@angular/core';
import { FieldWithPermission } from 'src/app/models/helpers.model';

@Pipe({
  name: 'permissionsFilter',
})
export class PermissionsFilterPipe implements PipeTransform {
  transform(
    items: FieldWithPermission[],
    filterValue: string,
    isTitle: boolean
  ): any {
    if (!isTitle) {
      switch (filterValue) {
        case 'DynamicFields':
          return items.filter(
            (item) => item.objectRefName === 'Order' && item.dynamicFieldId
          );
        case 'Order':
          return items.filter(
            (item) => item.objectRefName === filterValue && !item.dynamicFieldId
          );
        default:
          return items.filter((item) => item.objectRefName === filterValue);
      }
    } else {
      switch (filterValue) {
        case 'DynamicFields':
          return (
            items.filter(
              (item) => item.objectRefName === 'Order' && item.dynamicFieldId
            ).length !== 0
          );
        case 'Order':
          return (
            items.filter(
              (item) =>
                item.objectRefName === filterValue && !item.dynamicFieldId
            ).length !== 0
          );
        default:
          return (
            items.filter((item) => item.objectRefName === filterValue)
              .length !== 0
          );
      }
    }
  }
}
