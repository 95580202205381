import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { pipe, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Role } from 'src/app/models/role.model';
import { ToastrService } from 'ngx-toastr';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { LoadingService } from 'src/app/services/loading.service';
import { User } from 'src/app/models/user.model';
import * as intlTelInput from 'intl-tel-input';
import { telErrorMap } from 'src/app/dashboard/helpers/tel-errors';
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() user: any;
  @Input() roles: Role[] = [];
  @Input() isBodyLocked!: boolean;
  @Output() modalState = new EventEmitter<{
    isDelete: boolean;
    isUpdate: boolean;
  }>();
  @ViewChild('editModal') editModalEl!: ElementRef;
  selectedRole!: Role;
  isRoleChanged = false;
  userLastActivity!: string;
  availableRoles!: Role[];
  private destroy = new Subject<boolean>();

  userForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[ a-zA-Z\-\']+$/),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[ a-zA-Z\-\']+$/),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]+$/),
    ]),
    receiveEmailNotificationWhenOrderDetailsChanged: new FormControl(),
    receiveEmailNotificationWhenOrderFileUploaded: new FormControl(),
    receiveEmailNotificationWhenOrderStageChanged: new FormControl(),
  });

  userTelData: any;
  telError!: string;
  isTelCustomError!: boolean;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private loadingService: LoadingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes['isBodyLocked'].currentValue) {
        disableBodyScroll(this.editModalEl.nativeElement);
      }
    }, 0);
    this.getUserData();
  }

  ngOnInit(): void {
    this.selectedRole = this.user.role;
    this.filterRoles();

    this.userForm.patchValue({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      receiveEmailNotificationWhenOrderDetailsChanged:
        this.user.receiveEmailNotificationWhenOrderDetailsChanged,
      receiveEmailNotificationWhenOrderFileUploaded:
        this.user.receiveEmailNotificationWhenOrderFileUploaded,
      receiveEmailNotificationWhenOrderStageChanged:
        this.user.receiveEmailNotificationWhenOrderStageChanged,
    });
  }

  ngAfterViewInit(): void {
    this.createPhoneElement();
  }

  createPhoneElement() {
    const telInputEl = document.getElementById('phoneNumber');

    if (telInputEl) {
      this.userTelData = intlTelInput(telInputEl, {
        initialCountry: 'US',
        separateDialCode: true,
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.js',
      });
      if (this.user.phoneNumber) {
        this.userTelData.setNumber(this.user.phoneNumber);
      }
    }
  }

  validateTel() {
    if (!this.userTelData.isPossibleNumber()) {
      this.isTelCustomError = true;
      this.telError = telErrorMap[this.userTelData.getValidationError()];

      if (!this.telError) {
        this.telError = 'Invalid number';
      }
    } else {
      this.isTelCustomError = false;
    }
  }

  filterRoles() {
    if (
      (this.user.role.parentRole !== null &&
        this.user.role.parentRole.name === 'ColdStorage') ||
      this.user.role.name === 'ColdStorage'
    ) {
      this.availableRoles = this.roles.filter(
        (r: Role) =>
          (r.parentRole !== null && r.parentRole.name === 'ColdStorage') ||
          r.name === 'ColdStorage'
      );
    } else if (
      (this.user.role.parentRole !== null &&
        this.user.role.parentRole.name === 'Customers') ||
      this.user.role.name === 'Customers'
    ) {
      this.availableRoles = this.roles.filter(
        (r: Role) =>
          (r.parentRole !== null && r.parentRole.name === 'Customers') ||
          r.name === 'Customers'
      );
    } else if (
      (this.user.role.parentRole !== null &&
        this.user.role.parentRole.name === 'CmpAdmin') ||
      this.user.role.name === 'CmpAdmin'
    ) {
      this.availableRoles = this.roles.filter(
        (r: Role) =>
          (r.parentRole !== null && r.parentRole.name === 'CmpAdmin') ||
          r.name === 'CmpAdmin'
      );
    } else {
      this.availableRoles = [...this.roles];
    }
  }

  getUserData() {
    this.userService
      .getUserById(this.user.id)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (resp: User) => {
          this.userLastActivity = resp.lastActivityOn ?? '';
        },
      });
  }

  changeRole() {
    if (this.selectedRole.id !== this.user.role.id) {
      this.isRoleChanged = true;
    } else {
      this.isRoleChanged = false;
    }
  }

  editUserRole() {
    const payload = {
      companyId: this.user.companyId,
      roleId: this.selectedRole.id,
    };

    this.userService
      .updateUserRole(this.user.id, payload)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: () => {
          this.modalState.emit({ isDelete: false, isUpdate: true });
          this.toastr.success('User role updated', 'Success');
          this.isRoleChanged = false;
        },
        error: (err) => {
          this.modalState.emit({ isDelete: false, isUpdate: true });
          this.isRoleChanged = false;
        },
      });
  }

  editUser() {
    const payload = {
      firstName: this.userForm.controls.firstName.value ?? '',
      lastName: this.userForm.controls.lastName.value ?? '',
      phoneNumber: this.userTelData.getNumber() ?? '',
      receiveEmailNotificationWhenOrderDetailsChanged:
        this.userForm.controls.receiveEmailNotificationWhenOrderDetailsChanged
          .value,
      receiveEmailNotificationWhenOrderFileUploaded:
        this.userForm.controls.receiveEmailNotificationWhenOrderFileUploaded
          .value,
      receiveEmailNotificationWhenOrderStageChanged:
        this.userForm.controls.receiveEmailNotificationWhenOrderStageChanged
          .value,
    };
    this.userService
      .editUser(this.user.id, payload)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (resp) => {
          this.modalState.emit({ isDelete: false, isUpdate: true });
          this.toastr.success('User updated', 'Success');
        },
        error: (err) => {},
      });
  }

  forgotPassword() {
    this.loadingService.changeLoadingStatus(true);
    const payload = {
      email: this.user.email ?? '',
    };
    this.userService
      .forgotPassword(payload)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: () => {
          this.loadingService.changeLoadingStatus(false);
          this.toastr.success(
            'Email with instructions was sent to user',
            'Success'
          );
        },
        error: () => {
          this.loadingService.changeLoadingStatus(false);
        },
      });
  }

  deleteAccount() {
    this.modalState.emit({ isDelete: true, isUpdate: false });
  }

  closeModal() {
    this.modalState.emit({ isDelete: false, isUpdate: false });
    enableBodyScroll(this.editModalEl.nativeElement);
  }

  backdropClick(event: Event) {
    if (event.target === event.currentTarget) {
      this.modalState.emit({ isDelete: false, isUpdate: false });
      enableBodyScroll(this.editModalEl.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
    clearAllBodyScrollLocks();
  }
}
