<div
  class="track"
  [ngClass]="{
    'on-state': state === 1,
    'off-state': state === 0,
    'unspecified-state': state === 2
  }"
  (click)="swithButtonClicked()"
>
  <div class="thumb"></div>
</div>
