import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-switch',
  templateUrl: './custom-switch.component.html',
  styleUrls: ['./custom-switch.component.scss'],
})
export class CustomSwitchComponent {
  @Output() stateChange = new EventEmitter<number>();
  @Input() state!: number;
  prevState!: number;

  swithButtonClicked() {
    switch (this.state) {
      case 0:
        this.stateChange.emit(2);
        this.state = 2;
        break;
      case 1:
        this.stateChange.emit(0);
        this.state = 0;
        break;
      case 2:
        this.stateChange.emit(1);
        this.state = 1;
    }
  }
}
