import { Pipe, PipeTransform } from '@angular/core';
import { ListOfOrderFields } from 'src/app/models/field-list.model';

@Pipe({
  name: 'orderFieldsFilter',
})
export class OrderFieldsFilterPipe implements PipeTransform {
  transform(
    items: ListOfOrderFields[],
    filterValue: string,
    isTitle: boolean
  ): any {
    if (!isTitle) {
      return items.filter((item) => item.objectRefName === filterValue);
    } else {
      return (
        items.filter((item) => item.objectRefName === filterValue).length !== 0
      );
    }
  }
}
