<div
  class="doc-preview modal-el"
  [ngStyle]="{ visibility: showDoc ? 'visible' : 'hidden' }"
  (click)="backdropClick($event)"
>
  <div class="doc-file modal-info-box">
    <div class="progress-bar" *ngIf="!allowSwitch">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="doc-header">
      <div class="doc-file-title">
        {{ fileName }}
      </div>
      <div class="doc-file-actions">
        <div
          class="mask-toggle"
          [title]="showlayout ? 'Print layout' : 'Print without layout'"
        >
          <mat-slide-toggle
            (click)="allowSwitch = false"
            [disabled]="!allowSwitch"
            [(ngModel)]="showlayout"
          ></mat-slide-toggle>
        </div>
        <img
          src="/assets/img/icons/download_doc.svg"
          alt="download"
          title="Download"
          (click)="downloadDoc()"
        />
        <img
          src="/assets/img/icons/print_doc.svg"
          alt="print"
          title="Print"
          (click)="printDoc()"
        />
      </div>
    </div>
    <div class="doc-content">
      <pdf-viewer
        [src]="showlayout ? srcData : srcImgData"
        [show-all]="true"
        [render-text]="true"
        [original-size]="true"
        [fit-to-page]="true"
        (after-load-complete)="docLoaded()"
        style="height: 100%"
        #pdfFile
      ></pdf-viewer>
    </div>
  </div>
</div>
