<div class="page-wrapper">
  <div class="title-box">
    <div class="title">
      <span *ngIf="pageName === 'orders'">Orders</span>
      <span *ngIf="pageName === 'reports'">Reports</span>
      <div class="title-count" *ngIf="paginationData && pageName === 'orders'">
        {{ paginationData.totalCount }}
        <span *ngIf="paginationData.totalCount !== 1">orders</span>
        <span *ngIf="paginationData.totalCount === 1">order</span>
      </div>
    </div>
    <div class="date-select">
      <app-date-range-filter
        (selectedRange)="getRange($event)"
      ></app-date-range-filter>
    </div>
  </div>

  <div class="search-box">
    <div
      class="form-control"
      [ngClass]="{
        'orders-control': pageName === 'orders',
        'reports-control': pageName === 'reports'
      }"
    >
      <div class="icon">
        <img src="/assets/img/icons/search.svg" alt="search" />
      </div>
      <input
        type="text"
        placeholder="Search..."
        (input)="searchOrder()"
        [(ngModel)]="searchString"
      />
    </div>
    <button
      mat-stroked-button
      class="filter-btn"
      (click)="toggleFilterOptions()"
    >
      <mat-icon>filter_list</mat-icon>
      <span>Filters</span>
    </button>
    <button
      mat-button
      class="excel-btn"
      (click)="exportToExcel()"
      *ngIf="pageName === 'reports'"
    >
      Export to Excel
    </button>
  </div>

  <div class="filter-box" *ngIf="orders && pageName === 'orders'">
    <div
      class="all-orders filter-btn active-filter"
      (click)="filterByStatus('all')"
    >
      All <span class="divider">.</span
      >{{ ordersStats.open + ordersStats.canceled + ordersStats.closed }}
    </div>
    <div
      class="open-orders filter-btn"
      (click)="filterByStatus('open', orderStatus.Open)"
    >
      Open <span class="divider">.</span>{{ ordersStats.open }}
    </div>
    <div
      class="canceled-orders filter-btn"
      (click)="filterByStatus('canceled', orderStatus.Canceled)"
    >
      Canceled <span class="divider">.</span>{{ ordersStats.canceled }}
    </div>
    <div
      class="closed-orders filter-btn"
      (click)="filterByStatus('closed', orderStatus.Closed)"
    >
      Closed <span class="divider">.</span>{{ ordersStats.closed }}
    </div>
  </div>

  <div class="table-box">
    <table
      mat-table
      [dataSource]="dataSource"
      class="data-table orders-table"
      id="orders-table"
    >
      <ng-container
        *ngFor="let column of tableColumnsObj"
        [matColumnDef]="column.columnDef"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'sortable-header': !column.isCustom }"
          (click)="
            sortOrders(
              column.filterKey,
              column.isCustom,
              column.type,
              column.fieldId
            )
          "
        >
          <div class="table-header-wrapper" *ngIf="!column.isCustom">
            {{ column.header }}
            <div
              class="sort-controls"
              *ngIf="selectedSort.field === column.filterKey"
            >
              <img
                src="/assets/img/icons/sort_arrow_up.svg"
                alt="up"
                *ngIf="selectedSort.mode !== 'desc'"
              />
              <img
                src="/assets/img/icons/sort_arrow_down.svg"
                alt="down"
                *ngIf="selectedSort.mode !== 'asc'"
              />
            </div>
            <div
              class="sort-controls"
              *ngIf="selectedSort.field !== column.filterKey"
            >
              <img src="/assets/img/icons/sort_arrow_up.svg" alt="up" />
              <img src="/assets/img/icons/sort_arrow_down.svg" alt="down" />
            </div>
          </div>
          <div class="table-header-wrapper" *ngIf="column.isCustom">
            {{ column.header }}
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let order"
          [ngClass]="{
            'po-number': column.columnDef === 'poNumber',
            'date-info':
              column.columnDef === 'createdOn' ||
              column.columnDef === 'lastModifiedOn',
            'consignee-info': column.columnDef === 'consigneeName',
            'order-stage': column.columnDef === 'stage'
          }"
        >
          <div class="num-box" *ngIf="column.columnDef === 'poNumber'">
            <div class="new-label" *ngIf="order.isNew">new</div>
            {{ column.cell(order) }}
          </div>

          <div class="num-box" *ngIf="column.columnDef === 'orderNumber'">
            {{ column.cell(order) !== "N/A" ? column.cell(order) : "-" }}
          </div>

          <div
            class="status-label"
            *ngIf="column.columnDef === 'status'"
            [ngClass]="{
              'open-label': order.status === orderStatus.Open,
              'closed-label': order.status === orderStatus.Closed,
              'canceled-label': order.status === orderStatus.Canceled
            }"
          >
            <span class="status-mark"></span>
            <span class="status-text">{{
              order.status === 0
                ? "Open"
                : order.status === 1
                ? "Closed"
                : "Canceled"
            }}</span>
          </div>

          <div
            *ngIf="
              column.columnDef === 'createdOn' ||
              column.columnDef === 'lastModifiedOn'
            "
          >
            {{ column.cell(order) | date : "MM/dd/yyyy hh:mm a" }}
          </div>

          <div *ngIf="column.columnDef === 'stage'" class="stage-table-cell">
            <div class="stage-hidden-title" style="display: none">
              <span *ngIf="order.stage === orderStage.Stage1"
                >Stage 1 &nbsp;</span
              >
              <span *ngIf="order.stage === orderStage.Stage2A"
                >Stage 2A &nbsp;</span
              >
              <span *ngIf="order.stage === orderStage.Stage2B"
                >Stage 2B &nbsp;</span
              >
              <span *ngIf="order.stage === orderStage.Stage3"
                >Stage 3 &nbsp;</span
              >
              <span *ngIf="order.stage === orderStage.Stage4"
                >Stage 4 &nbsp;</span
              >
            </div>
            <div class="stage-title" *ngIf="order.stage === orderStage.Stage1">
              Customer
            </div>
            <div
              class="stage-title"
              *ngIf="
                order.stage === orderStage.Stage2A ||
                order.stage === orderStage.Stage3
              "
            >
              CMP Invertory
            </div>
            <div
              class="stage-title"
              *ngIf="
                order.stage === orderStage.Stage2B ||
                order.stage === orderStage.Stage4
              "
            >
              Cold Storage
            </div>
            <div class="stage-diagram">
              <div
                class="diagram-cell"
                [ngClass]="{
                  completed:
                    order.status === orderStatus.Closed ||
                    order.stage > orderStage.Stage3,
                  one:
                    order.status !== orderStatus.Closed &&
                    order.stage1NotifiedOn
                }"
              ></div>
              <div
                class="diagram-cell"
                [ngClass]="{
                  completed:
                    order.status === orderStatus.Closed ||
                    order.stage > orderStage.Stage3,
                  two:
                    order.status !== orderStatus.Closed &&
                    order.stage2ANotifiedOn
                }"
              ></div>
              <div
                class="diagram-cell"
                [ngClass]="{
                  completed:
                    order.status === orderStatus.Closed ||
                    order.stage > orderStage.Stage3,
                  three:
                    order.status !== orderStatus.Closed &&
                    order.stage2BNotifiedOn
                }"
              ></div>
              <div
                class="diagram-cell"
                [ngClass]="{
                  completed:
                    order.status === orderStatus.Closed ||
                    order.stage > orderStage.Stage3
                }"
              ></div>
              <div
                class="diagram-cell"
                [ngClass]="{
                  completed: order.status === orderStatus.Closed
                }"
              ></div>
            </div>
            <div class="done-mark" *ngIf="order.status === orderStatus.Closed">
              <mat-icon>check_circle</mat-icon>
            </div>
          </div>

          <div
            *ngIf="column.columnDef === 'customerName'"
            (click)="goToCustomer($event, order.customerId)"
          >
            {{ column.cell(order) | nullTransform }}
          </div>

          <div *ngIf="column.columnDef === 'countryId'">
            {{ column.cell_id(order) }}
          </div>

          <div
            *ngIf="
              column.columnDef === 'consigneeName' ||
              column.columnDef === 'coldStorageName'
            "
          >
            {{ column.cell(order) | nullTransform }}
          </div>

          <div *ngIf="column.isCustom">
            <span *ngIf="column.type === customFieldType.DateTime">{{
              column.cell_alt(order) | date : "MM/dd/yyyy hh:mm a"
            }}</span>
            <span *ngIf="column.type !== customFieldType.DateTime">{{
              column.cell_alt(order)
            }}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [routerLink]="['../orders', row.id]"
        class="order-data-row"
      >
        <a></a>
      </tr>

      <tr class="mat-row no-data-row" *matNoDataRow>
        <td class="mat-cell no-data-cell" colspan="1">
          No data matching the filter
        </td>
      </tr>
    </table>
  </div>
  <app-paginator
    [records]="paginationData"
    (pageSettings)="getPageSettings($event)"
  ></app-paginator>
</div>

<div
  class="filter-modal modal-el"
  (click)="hideModal($event)"
  *ngIf="showFilterModal"
>
  <div class="filters-box modal-info-box">
    <div class="filter-title">Manage Columns</div>
    <div class="filter-options">
      <div class="columns-options">
        <div class="col-title">
          <span>Select columns</span>
          <span class="reorder-hint">(Drag to reorder)</span>
        </div>
        <div class="col-list">
          <ul id="columns-list">
            <li
              *ngFor="let col of reorderedColumns; let i = index"
              draggable="true"
              (dragstart)="dragItem($event, col)"
              (dragenter)="disableDefaultBehavior($event)"
              (dragover)="disableDefaultBehavior($event)"
              (drop)="dropItem($event)"
              (click)="selectLine(col)"
              [id]="col"
              class="li-line"
              [ngClass]="{ 'hidden-filter': hideCustomField(col) }"
            >
              <div class="li" (click)="selectCol(col)">
                <div class="drag-icon">
                  <mat-icon>menu</mat-icon>
                </div>
                <div class="item-title">
                  {{
                    col === "poNumber"
                      ? "P. O. NO."
                      : col === "coldStorageName"
                      ? "Cold Storage"
                      : col === "customerName"
                      ? "Customer"
                      : col === "consigneeName"
                      ? "Consignee"
                      : col === "createdOn"
                      ? "Created"
                      : col === "lastModifiedOn"
                      ? "Modified"
                      : col === "countryId"
                      ? "Destination country"
                      : col
                  }}
                </div>
                <div class="visibility-checkbox">
                  <mat-checkbox
                    class="remember"
                    [(ngModel)]="columnsStates[i]"
                    (change)="showHideColumn(i, col, $event)"
                  ></mat-checkbox>
                </div>
              </div>
              <div
                class="funnel-icon"
                [ngClass]="{ 'funnel-icon-gray': !isSettedFilter(col) }"
              >
                <img
                  *ngIf="!isSettedFilter(col)"
                  src="/assets/img/icons/funnel.svg"
                  alt="filter"
                />
                <img
                  *ngIf="isSettedFilter(col)"
                  src="/assets/img/icons/funnel_active.svg"
                  alt="filter"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="reset-box" (click)="resetFilters()">Reset</div>
      </div>
      <div class="orders-options">
        <div
          class="field-form"
          *ngFor="let fieldFilter of allFieldFilters; let i = index"
        >
          <div class="f-form" *ngIf="selectedCol === fieldFilter.name">
            <div (click)="removeValue(fieldFilter)" class="clear">Clear</div>
            <div class="col-title">
              {{
                fieldFilter.name === "countryId"
                  ? "Destination country"
                  : formatName(fieldFilter.name)
              }}
            </div>
            <div class="form-control" *ngIf="fieldFilter.type === 'enum'">
              <div *ngIf="fieldFilter.name === 'status'">
                <mat-form-field appearance="outline">
                  <mat-select
                    disableRipple
                    [disabled]="!fieldFilter.isShow"
                    [(ngModel)]="fieldFilter.value"
                  >
                    <mat-option
                      *ngFor="let option of statusEnumValues"
                      [value]="option.value"
                      >{{ option.name }}</mat-option
                    >
                  </mat-select>
                  <mat-icon matSuffix class="select-icon-replacement"
                    >expand_more</mat-icon
                  >
                </mat-form-field>
              </div>
              <div *ngIf="fieldFilter.name === 'stage'">
                <mat-form-field appearance="outline">
                  <mat-select
                    disableRipple
                    [disabled]="!fieldFilter.isShow"
                    [(ngModel)]="fieldFilter.value"
                  >
                    <mat-option
                      *ngFor="let option of stageEnumValues"
                      [value]="option.value"
                      [disabled]="
                        option.value === orderStage.Stage1 ||
                        option.value === orderStage.Stage2A ||
                        option.value === orderStage.Stage2B
                      "
                      >{{ option.name }}</mat-option
                    >
                  </mat-select>
                  <mat-icon matSuffix class="select-icon-replacement"
                    >expand_more</mat-icon
                  >
                </mat-form-field>
              </div>
            </div>
            <div
              class="form-control"
              *ngIf="
                (fieldFilter.type === 'text' || fieldFilter.type === 'list') &&
                fieldFilter.name !== 'countryId'
              "
            >
              <input
                type="text"
                placeholder="Value"
                [disabled]="!fieldFilter.isShow"
                [(ngModel)]="fieldFilter.value"
              />
            </div>
            <div class="form-control" *ngIf="fieldFilter.name === 'countryId'">
              <mat-form-field appearance="outline">
                <mat-select
                  disableRipple
                  [disabled]="!fieldFilter.isShow"
                  [(ngModel)]="fieldFilter.value"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search..."
                      [formControl]="searchCountries"
                      noEntriesFoundLabel="No countries found"
                      [clearSearchInput]="false"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country.id"
                    >{{ country.name }}</mat-option
                  >
                </mat-select>
                <mat-icon matSuffix class="select-icon-replacement"
                  >expand_more</mat-icon
                >
              </mat-form-field>
            </div>
            <div class="form-control" *ngIf="fieldFilter.type === 'number'">
              <input
                type="number"
                placeholder="Value"
                [disabled]="!fieldFilter.isShow"
                [(ngModel)]="fieldFilter.value"
              />
            </div>
            <div class="form-control" *ngIf="fieldFilter.type === 'date'">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [disabled]="!fieldFilter.isShow"
                  [matDatepicker]="picker"
                  [(ngModel)]="fieldFilter.value"
                  class="date-input"
                  (click)="picker.open()"
                />
                <mat-datepicker-toggle matIconSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="radio-options">
              <mat-radio-group
                [disabled]="!fieldFilter.isShow"
                [(ngModel)]="fieldFilter.operator"
              >
                <mat-radio-button value="Equals">is</mat-radio-button>
                <mat-radio-button value="!Equals">is not</mat-radio-button>
                <mat-radio-button
                  value="Contains"
                  *ngIf="
                    fieldFilter.type !== 'number' &&
                    fieldFilter.type !== 'date' &&
                    fieldFilter.type !== 'enum' &&
                    fieldFilter.name !== 'countryId'
                  "
                  >contains</mat-radio-button
                >
                <mat-radio-button
                  value="GreaterThan"
                  *ngIf="fieldFilter.type === 'number'"
                  >greater than</mat-radio-button
                >
                <mat-radio-button
                  value="GreaterThanOrEqual"
                  *ngIf="fieldFilter.type === 'number'"
                  >greater than or equal</mat-radio-button
                >
                <mat-radio-button
                  value="LessThan"
                  *ngIf="fieldFilter.type === 'number'"
                  >less than</mat-radio-button
                >
                <mat-radio-button
                  value="LessThanOrEqual"
                  *ngIf="fieldFilter.type === 'number'"
                  >less than or equal</mat-radio-button
                >
                <mat-radio-button
                  value="Any"
                  *ngIf="
                    (fieldFilter.type === 'text' ||
                      fieldFilter.type === 'number') &&
                    fieldFilter.name !== 'countryId'
                  "
                  >has any value</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="reset-multi-filters" (click)="resetMultiFilters()">
          Reset Filters
        </div>
      </div>
    </div>
    <div class="filter-actions form-actions">
      <button
        mat-stroked-button
        class="cancel-btn"
        (click)="cancelFiltering(); toggleFilterOptions()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="action-btn"
        (click)="
          isApplyFilters = true;
          applyMultiFilters();
          applyFilters();
          toggleFilterOptions()
        "
      >
        Submit Filters
      </button>
    </div>
  </div>
</div>

<div class="modal-el export-spinner" #exportModal></div>
