import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileMetadata } from '../models/helpers.model';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  constructor(private http: HttpClient) {}

  createAttachment(data: any): Observable<string> {
    return this.http.post<string>('/attachments', data);
  }

  getAttachment(id: string): Observable<any> {
    return this.http.get<any>(`/attachments/${id}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  getMetadata(id: string): Observable<FileMetadata> {
    return this.http.get<FileMetadata>(`/attachments/${id}/metadata`);
  }
}
