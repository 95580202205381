import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if ([401, 403].includes(error.status) && this.authService.userData) {
          this.authService.logout();
        }

        let errorMsg;
        if (typeof error.error === 'string') {
          errorMsg = JSON.parse(error.error);
        } else {
          errorMsg = error.error;
        }

        let err: string;
        if (errorMsg.messages) {
          err = errorMsg.messages.join(' ');
        } else {
          err = error.statusText;
        }

        if (err === 'Invalid Refresh Token.') {
          err =
            'Somebody logged in with your credentials from another computer. Your session closed, please log in again.';
        }
        if (err.includes('The order is already Approved by')) {
          err = err + '. Order data can not be changed.';
        }

        this.toastr.error(`${err}`, 'Error');
        return throwError(() => {});
      })
    );
  }
}
