import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { EditConsigneeComponent } from './components/modals/edit-consignee/edit-consignee.component';
import { CustomerConsigneesComponent } from './components/customer-consignees/customer-consignees.component';
import { EditUserComponent } from './components/modals/edit-user/edit-user.component';
import { DeleteUserComponent } from './components/modals/delete-user/delete-user.component';
import { CustomInfiniteScrollDirective } from '../directives/custom-infinite-scroll.directive';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { DragDropDirective } from '../directives/drag-drop.directive';
import { PdfPreviewComponent } from './components/modals/pdf-preview/pdf-preview.component';
import { PdfImgPreviewComponent } from './components/modals/pdf-img-preview/pdf-img-preview.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { CustomSwitchComponent } from './components/custom-switch/custom-switch.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NullTransformPipe } from '../dashboard/pipes/null-transform.pipe';
import { DateFilterPipe } from '../dashboard/pipes/date-filter.pipe';
import { PermissionsFilterPipe } from '../dashboard/pipes/permissions-filter.pipe';
import { OrderFieldsFilterPipe } from '../dashboard/pipes/order-fields-filter.pipe';
import { FieldsSettingsFilterPipe } from '../dashboard/pipes/fields-settings-filter.pipe';
import { OptionsFilterPipe } from '../dashboard/pipes/options-filter.pipe';
import { ConsigneeOrdersModalComponent } from './components/customer-consignees/components/consignee-orders-modal/consignee-orders-modal.component';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    PaginatorComponent,
    EditConsigneeComponent,
    CustomerConsigneesComponent,
    EditUserComponent,
    DeleteUserComponent,
    CustomInfiniteScrollDirective,
    ClickOutsideDirective,
    DragDropDirective,
    PdfPreviewComponent,
    PdfImgPreviewComponent,
    DateRangeFilterComponent,
    OrdersTableComponent,
    CustomSwitchComponent,
    NullTransformPipe,
    DateFilterPipe,
    PermissionsFilterPipe,
    OrderFieldsFilterPipe,
    FieldsSettingsFilterPipe,
    OptionsFilterPipe,
    ConsigneeOrdersModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatTabsModule,
    MatExpansionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    ClipboardModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    PdfViewerModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatTabsModule,
    MatExpansionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    ClipboardModule,
    DragDropModule,
    PaginatorComponent,
    EditConsigneeComponent,
    CustomerConsigneesComponent,
    EditUserComponent,
    DeleteUserComponent,
    PdfPreviewComponent,
    PdfImgPreviewComponent,
    DateRangeFilterComponent,
    OrdersTableComponent,
    CustomSwitchComponent,
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    PdfViewerModule,
    CustomInfiniteScrollDirective,
    ClickOutsideDirective,
    DragDropDirective,
    NgxMaskModule,
    NullTransformPipe,
    DateFilterPipe,
    PermissionsFilterPipe,
    OrderFieldsFilterPipe,
    FieldsSettingsFilterPipe,
    OptionsFilterPipe,
  ],
})
export class SharedModule {}
