import {
  Directive,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  @HostListener('document.click', ['$event.target'])
  onClick(target: HTMLElement) {
    const clickInside = this.elRef.nativeElement.contains(target);

    if (!clickInside) {
      this.clickOutside.emit();
    }
  }

  constructor(private elRef: ElementRef) {}
}
