<div class="new-consignee-modal modal-el">
  <div class="modal-info-box consignee-info">
    <div class="title" *ngIf="!consigneeForEdit">Add new consignee</div>
    <div class="title" *ngIf="consigneeForEdit">Update consignee</div>
    <div class="consignee-form">
      <form [formGroup]="consigneeForm">
        <div class="double-controls">
          <div class="form-control">
            <label for="consigneeName">Consignee name *</label>
            <input
              type="text"
              id="consigneeName"
              placeholder="Enter consignee name"
              formControlName="name"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.name?.touched &&
                  consigneeForm.controls.name?.hasError('required')
                "
                >Consignee name is required</span
              >
              <span
                *ngIf="
                  consigneeForm.controls.name?.touched &&
                  consigneeForm.controls.name?.hasError('pattern')
                "
                >Incorrect consignee name</span
              >
            </div>
          </div>
          <div class="form-control">
            <label>Country *</label>
            <mat-form-field appearance="outline">
              <mat-select disableRipple formControlName="countryId">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search..."
                    [formControl]="searchCountries"
                    noEntriesFoundLabel="No countries found"
                    [clearSearchInput]="false"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.id"
                  >{{ country.name }}</mat-option
                >
              </mat-select>
              <mat-icon matSuffix class="select-icon-replacement"
                >expand_more</mat-icon
              >
            </mat-form-field>
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.countryId?.touched &&
                  consigneeForm.controls.countryId?.hasError('required')
                "
                >Country is required</span
              >
            </div>
          </div>
        </div>
        <div class="double-controls">
          <div class="form-control">
            <label for="city">City *</label>
            <input
              type="text"
              id="city"
              placeholder="Enter city"
              formControlName="city"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.city?.touched &&
                  consigneeForm.controls.city?.hasError('required')
                "
                >City is required</span
              >
              <span
                *ngIf="
                  consigneeForm.controls.city?.touched &&
                  consigneeForm.controls.city?.hasError('pattern')
                "
                >Incorrect city name</span
              >
            </div>
          </div>
          <div class="form-control">
            <label for="address">Address *</label>
            <input
              type="text"
              id="address"
              placeholder="Enter address"
              formControlName="address"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.address?.touched &&
                  consigneeForm.controls.address?.hasError('required')
                "
                >Address is required</span
              >
              <span
                *ngIf="
                  consigneeForm.controls.address?.touched &&
                  consigneeForm.controls.address?.hasError('pattern')
                "
                >Incorrect address</span
              >
            </div>
          </div>
        </div>
        <div class="double-controls">
          <div class="form-control">
            <label for="province">Province or State</label>
            <input
              type="text"
              id="province"
              placeholder="Enter province or state"
              formControlName="province"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.province?.touched &&
                  consigneeForm.controls.province?.hasError('required')
                "
                >Province is required</span
              >
              <span
                *ngIf="
                  consigneeForm.controls.province?.touched &&
                  consigneeForm.controls.province?.hasError('pattern')
                "
                >Incorrect province</span
              >
            </div>
          </div>
          <div class="form-control">
            <label for="zip">Code (Postal/Zip) *</label>
            <input
              type="text"
              id="zip"
              placeholder="Enter code (postal/zip)"
              formControlName="zipCode"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.zipCode?.touched &&
                  consigneeForm.controls.zipCode?.hasError('required')
                "
                >Code is required</span
              >
              <span
                *ngIf="
                  consigneeForm.controls.zipCode?.touched &&
                  consigneeForm.controls.zipCode?.hasError('pattern')
                "
                >Incorrect code</span
              >
            </div>
          </div>
        </div>
        <div class="lines-box">
          <div class="double-controls">
            <div class="form-control">
              <label for="line_one">Line 1</label>
              <input type="text" id="line_one" formControlName="line1" />
              <div class="field-error">
                <span
                  *ngIf="
                    consigneeForm.controls.line1?.touched &&
                    consigneeForm.controls.line1?.hasError('pattern')
                  "
                  >Use only latin letters, symbols and numbers</span
                >
              </div>
            </div>
            <div class="form-control">
              <label for="line_two">Line 2</label>
              <input type="text" id="line_two" formControlName="line2" />
              <div class="field-error">
                <span
                  *ngIf="
                    consigneeForm.controls.line2?.touched &&
                    consigneeForm.controls.line2?.hasError('pattern')
                  "
                  >Use only latin letters, symbols and numbers</span
                >
              </div>
            </div>
          </div>
          <div class="double-controls">
            <div class="form-control">
              <label for="line_three">Line 3</label>
              <input type="text" id="line_three" formControlName="line3" />
              <div class="field-error">
                <span
                  *ngIf="
                    consigneeForm.controls.line3?.touched &&
                    consigneeForm.controls.line3?.hasError('pattern')
                  "
                  >Use only latin letters, symbols and numbers</span
                >
              </div>
            </div>
            <div class="form-control">
              <label for="line_four">Line 4</label>
              <input type="text" id="line_four" formControlName="line4" />
              <div class="field-error">
                <span
                  *ngIf="
                    consigneeForm.controls.line4?.touched &&
                    consigneeForm.controls.line4?.hasError('pattern')
                  "
                  >Use only latin letters, symbols and numbers</span
                >
              </div>
            </div>
          </div>
          <div class="double-controls">
            <div class="form-control">
              <label for="line_five">Line 5</label>
              <input type="text" id="line_five" formControlName="line5" />
              <div class="field-error">
                <span
                  *ngIf="
                    consigneeForm.controls.line5?.touched &&
                    consigneeForm.controls.line5?.hasError('pattern')
                  "
                  >Use only latin letters, symbols and numbers</span
                >
              </div>
            </div>
            <div class="form-control">
              <label for="line_six">Line 6</label>
              <input type="text" id="line_six" formControlName="line6" />
              <div class="field-error">
                <span
                  *ngIf="
                    consigneeForm.controls.line6?.touched &&
                    consigneeForm.controls.line6?.hasError('pattern')
                  "
                  >Use only latin letters, symbols and numbers</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="person-title">Key Person</div>
        <div class="double-controls">
          <div class="form-control">
            <label for="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              placeholder="Enter first name"
              formControlName="firstName"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.firstName?.touched &&
                  consigneeForm.controls.firstName?.hasError('pattern')
                "
                >Incorrect name</span
              >
            </div>
          </div>
          <div class="form-control">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              placeholder="Enter last name"
              formControlName="lastName"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.lastName?.touched &&
                  consigneeForm.controls.lastName?.hasError('pattern')
                "
                >Incorrect last name</span
              >
            </div>
          </div>
        </div>
        <div class="double-controls">
          <div class="form-control">
            <label for="email">Email</label>
            <input
              type="text"
              id="email"
              placeholder="Enter email address"
              formControlName="email"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.email?.touched &&
                  consigneeForm.controls.email?.hasError('pattern')
                "
                >Incorrect email</span
              >
            </div>
          </div>
          <div class="form-control">
            <label for="phone">Phone</label>
            <input
              type="text"
              id="phone"
              placeholder="Enter phone number"
              formControlName="phone"
              (input)="validateTel()"
            />
            <div class="field-error">
              <span
                *ngIf="
                  consigneeForm.controls.phone?.touched &&
                  consigneeForm.controls.phone?.hasError('pattern')
                "
                >Invalid number 111</span
              >
              <span *ngIf="isTelCustomError">{{ telError }}</span>
            </div>
          </div>
        </div>
      </form>
      <div class="form-actions">
        <button
          mat-button
          class="delete-btn"
          *ngIf="consigneeForEdit"
          (click)="deleteConsignee()"
        >
          Delete Consignee
        </button>
        <button mat-stroked-button class="cancel-btn" (click)="closeModal()">
          Cancel
        </button>
        <button
          mat-button
          class="action-btn"
          [disabled]="consigneeForm.invalid || isTelCustomError"
          (click)="addNewConsignee()"
        >
          <span *ngIf="!consigneeForEdit">Add Consignee</span>
          <span *ngIf="consigneeForEdit">Update Consignee</span>
        </button>
      </div>
    </div>
  </div>
</div>
