import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, startWith } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  baseUrl = environment.apiUrl;

  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const reqUrl = request.clone({
      url: this.appendBaseUrl(request.url),
    });

    if (
      !request.url.startsWith('/tokens') &&
      !request.url.startsWith('/users/reset-password')
    ) {
      const tokenData = this.authService.getTokenData();
      const token = tokenData ? JSON.parse(tokenData).token : '';
      if (!token) {
        this.router.navigate(['']);
      }

      const reqToken = reqUrl.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });

      return next.handle(reqToken);
    }
    return next.handle(reqUrl);
  }

  appendBaseUrl(url: string) {
    if (url.startsWith('http') || url.startsWith('https')) {
      return url;
    }
    if (!url.startsWith('/')) {
      url = '/' + url;
    }
    return this.baseUrl + url;
  }
}
