import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { combineLatestWith, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Consignee } from 'src/app/models/consignee.model';
import { ConsigneeService } from 'src/app/services/consignee.service';
import { CountriesService } from 'src/app/services/countries.service';
import { ToastrService } from 'ngx-toastr';
import { Country } from 'src/app/models/country.model';
import { LoadingService } from 'src/app/services/loading.service';
import * as intlTelInput from 'intl-tel-input';
import { telErrorMap } from 'src/app/dashboard/helpers/tel-errors';
@Component({
  selector: 'app-edit-consignee',
  templateUrl: './edit-consignee.component.html',
  styleUrls: ['./edit-consignee.component.scss'],
})
export class EditConsigneeComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() consigneeForEditId!: string | null | undefined;
  @Input() companyId!: string;
  @Output() modalState = new EventEmitter<{
    isDelete: boolean;
    isUpdate: boolean;
  }>();
  @Output() newConsignee = new EventEmitter<Consignee>();
  textPattern = /^[ A-Za-z0-9_@./#!?*()$%^&+-=']*$/;
  consigneeForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[ 0-9a-zA-Z,()!#$%^&*'-_+/=\-\']+$/),
    ]),
    countryId: new FormControl('', [Validators.required]),
    city: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[ a-zA-Z\-\']+$/),
    ]),
    address: new FormControl('', [
      Validators.required,
      Validators.pattern(this.textPattern),
    ]),
    province: new FormControl('', [Validators.pattern(this.textPattern)]),
    zipCode: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[ A-Za-z0-9_@./#!?*()$%^&+-=]*$/),
    ]),
    line1: new FormControl('', [Validators.pattern(this.textPattern)]),
    line2: new FormControl('', [Validators.pattern(this.textPattern)]),
    line3: new FormControl('', [Validators.pattern(this.textPattern)]),
    line4: new FormControl('', [Validators.pattern(this.textPattern)]),
    line5: new FormControl('', [Validators.pattern(this.textPattern)]),
    line6: new FormControl('', [Validators.pattern(this.textPattern)]),
    firstName: new FormControl('', [Validators.pattern(/^[ a-zA-Z\-\']+$/)]),
    lastName: new FormControl('', [Validators.pattern(/^[ a-zA-Z\-\']+$/)]),
    email: new FormControl('', [
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    phone: new FormControl('', [Validators.pattern(/^[0-9]+$/)]),
  });
  consigneeForEdit!: Consignee;
  countries: Country[] = [];
  countriesForSearch: Country[] = [];
  searchCountries: FormControl = new FormControl('');
  userTelData: any;
  telError!: string;
  isTelCustomError!: boolean;
  private destroy = new Subject<boolean>();

  constructor(
    private consigneeService: ConsigneeService,
    private countriesService: CountriesService,
    private toastr: ToastrService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.getCountries();
    this.searchCountries.valueChanges.pipe(takeUntil(this.destroy)).subscribe({
      next: (value: string) => {
        this.countries = this.countriesForSearch.filter((c: Country) =>
          c.name.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
    this.getConsignee();
  }

  ngAfterViewInit(): void {
    this.createPhoneElement();
  }

  listenFormChanges() {
    if (!this.consigneeForm.controls.line1.value) {
      this.consigneeForm.controls.name.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: (value) => {
            this.consigneeForm.controls.line1.patchValue(value);
          },
        });
    }

    if (!this.consigneeForm.controls.line2.value) {
      this.consigneeForm.controls.address.valueChanges
        .pipe(
          combineLatestWith(this.consigneeForm.controls.city.valueChanges),
          takeUntil(this.destroy)
        )
        .subscribe({
          next: ([address, city]) => {
            this.consigneeForm.controls.line2.patchValue(`${address}, ${city}`);
          },
        });
    }

    if (!this.consigneeForm.controls.line3.value) {
      this.consigneeForm.controls.province.valueChanges
        .pipe(
          combineLatestWith(
            this.consigneeForm.controls.countryId.valueChanges,
            this.consigneeForm.controls.zipCode.valueChanges
          ),
          takeUntil(this.destroy)
        )
        .subscribe({
          next: ([province, countryId, zipCode]) => {
            this.consigneeForm.controls.line3.patchValue(
              `${province}, ${
                this.getCountryById(countryId ?? '')?.name
              }, ${zipCode}`
            );
          },
        });
    }
  }

  createPhoneElement() {
    const telInputEl = document.getElementById('phone');

    if (telInputEl) {
      this.userTelData = intlTelInput(telInputEl, {
        initialCountry: 'US',
        separateDialCode: true,
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.js',
      });
    }
  }

  validateTel() {
    if (!this.userTelData.isPossibleNumber()) {
      this.isTelCustomError = true;
      this.telError = telErrorMap[this.userTelData.getValidationError()];

      if (!this.telError) {
        this.telError = 'Invalid number';
      }
    } else {
      this.isTelCustomError = false;
    }
  }

  getCountries() {
    this.countriesService.contriesList.pipe(takeUntil(this.destroy)).subscribe({
      next: (resp: Country[] | null) => {
        if (resp) {
          this.countries = resp.sort((a: Country, b: Country) => {
            return a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : 0;
          });
          this.countriesForSearch = resp;
        }
      },
    });
  }

  getCountryById(id: string): Country | undefined {
    return this.countries.find((c) => c.id === id);
  }

  getConsignee() {
    if (this.consigneeForEditId) {
      this.loadingService.changeLoadingStatus(true);
      this.consigneeService
        .getConsignee(this.consigneeForEditId)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: (resp: Consignee) => {
            this.consigneeForEdit = resp;
            this.patchConsigneeForm();
            this.loadingService.changeLoadingStatus(false);
          },
          error: () => {
            this.loadingService.changeLoadingStatus(false);
          },
        });
    } else {
      this.listenFormChanges();
    }
  }

  patchConsigneeForm() {
    if (this.consigneeForEdit) {
      this.consigneeForm.patchValue({
        name: this.consigneeForEdit.name,
        countryId: this.consigneeForEdit.countryId,
        city: this.consigneeForEdit.city,
        address: this.consigneeForEdit.address,
        province: this.consigneeForEdit.province,
        zipCode: this.consigneeForEdit.zipCode,
        line1: this.consigneeForEdit.line1
          ? this.consigneeForEdit.line1
          : this.consigneeForEdit.name,
        line2: this.consigneeForEdit.line2
          ? this.consigneeForEdit.line2
          : `${this.consigneeForEdit.address}, ${this.consigneeForEdit.city}`,
        line3: this.consigneeForEdit.line3
          ? this.consigneeForEdit.line3
          : `${this.consigneeForEdit.province}, ${
              this.getCountryById(this.consigneeForEdit.countryId)?.name
            }, ${this.consigneeForEdit.zipCode}`,
        line4: this.consigneeForEdit.line4,
        line5: this.consigneeForEdit.line5,
        line6: this.consigneeForEdit.line6,
        firstName: this.consigneeForEdit.firstName,
        lastName: this.consigneeForEdit.lastName,
        email: this.consigneeForEdit.email,
      });
      if (this.consigneeForEdit.phone) {
        this.userTelData.setNumber(this.consigneeForEdit.phone);
      }
    }
  }

  deleteConsignee() {
    if (this.consigneeForEdit && this.consigneeForEdit.id) {
      this.loadingService.changeLoadingStatus(true);
      this.consigneeService
        .deleteConsignee(this.consigneeForEdit.id)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: () => {
            this.modalState.emit({ isDelete: false, isUpdate: true });
            this.toastr.success('Consignee successfully deleted', 'Success');
            this.loadingService.changeLoadingStatus(false);
          },
          error: () => {
            this.loadingService.changeLoadingStatus(false);
          },
        });
    }
  }

  addNewConsignee() {
    this.loadingService.changeLoadingStatus(true);
    const payload: Partial<Consignee> = {
      name: this.consigneeForm.controls.name.value ?? '',
      companyId: this.companyId,
      countryId: this.consigneeForm.controls.countryId.value ?? '',
      city: this.consigneeForm.controls.city.value ?? '',
      address: this.consigneeForm.controls.address.value ?? '',
      province: this.consigneeForm.controls.province.value ?? '',
      zipCode: this.consigneeForm.controls.zipCode.value ?? '',
      line1: this.consigneeForm.controls.line1.value
        ? this.consigneeForm.controls.line1.value
        : this.consigneeForm.controls.name.value ?? '',
      line2: this.consigneeForm.controls.line2.value
        ? this.consigneeForm.controls.line2.value
        : `${this.consigneeForm.controls.address.value}, ${this.consigneeForm.controls.city.value}`,
      line3: this.consigneeForm.controls.line3.value
        ? this.consigneeForm.controls.line3.value
        : `${this.consigneeForm.controls.province.value}, ${
            this.getCountryById(
              this.consigneeForm.controls.countryId.value || ''
            )?.name
          }, ${this.consigneeForm.controls.zipCode.value}`,
      line4: this.consigneeForm.controls.line4.value ?? '',
      line5: this.consigneeForm.controls.line5.value ?? '',
      line6: this.consigneeForm.controls.line6.value ?? '',
      firstName: this.consigneeForm.controls.firstName.value ?? '',
      lastName: this.consigneeForm.controls.lastName.value ?? '',
      email: this.consigneeForm.controls.email.value ?? '',
      phone: this.userTelData.getNumber() ?? '',
    };
    if (!this.consigneeForEdit) {
      this.consigneeService
        .addConsignee(payload)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: (resp: Consignee) => {
            this.toastr.success('Consignee successfully added', 'Success');
            this.consigneeForm.reset();
            this.modalState.emit({ isDelete: false, isUpdate: true });
            this.newConsignee.emit(resp);
            this.loadingService.changeLoadingStatus(false);
          },
          error: () => {
            this.modalState.emit({ isDelete: false, isUpdate: false });
            this.loadingService.changeLoadingStatus(false);
          },
        });
    } else {
      payload.id = this.consigneeForEdit.id;
      this.consigneeService
        .updateConsignee(this.consigneeForEdit.id, payload)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: () => {
            this.toastr.success('Consignee successfully updated', 'Success');
            this.consigneeForm.reset();
            this.modalState.emit({ isDelete: false, isUpdate: true });
            this.loadingService.changeLoadingStatus(false);
          },
          error: () => {
            this.modalState.emit({ isDelete: false, isUpdate: false });
            this.loadingService.changeLoadingStatus(false);
          },
        });
    }
  }

  closeModal() {
    this.modalState.emit({ isDelete: false, isUpdate: false });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
