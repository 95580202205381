import {
  Component,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { OrdersService } from 'src/app/services/orders.service';
import { Order } from 'src/app/models/order.model';
import { FilterOptions } from 'src/app/models/filter.model';
import { FilterOperator } from 'src/app/enums/filter-operator';
import { LoadingService } from 'src/app/services/loading.service';
import { PaginationData, ResponseData } from 'src/app/models/helpers.model';
import { MatTableDataSource } from '@angular/material/table';
import { OrderStage } from 'src/app/enums/order-stage';
import { OrderStatus } from 'src/app/enums/order-status';
import { CountriesService } from 'src/app/services/countries.service';

@Component({
  selector: 'app-consignee-orders-modal',
  templateUrl: './consignee-orders-modal.component.html',
  styleUrls: ['./consignee-orders-modal.component.scss'],
})
export class ConsigneeOrdersModalComponent implements OnDestroy, OnChanges {
  @Input() consigneeId!: string;
  orders!: Order[];
  paginationData!: PaginationData;
  dataSource: any;
  orderStage = OrderStage;
  orderStatus = OrderStatus;
  searchOptions: FilterOptions = {
    advancedSearch: {
      fields: [],
      keyword: '',
    },
    keyword: '',
    advancedFilter: null,
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
  };
  selectedSort = { field: '', mode: '' };
  tableColumns = [
    'poNumber',
    'orderNumber',
    'stage',
    'status',
    'consignee',
    'customer',
    'coldStorage',
    'destination country',
    'created',
    'modified',
  ];
  private destroy = new Subject<boolean>();
  constructor(
    private ordersService: OrdersService,
    private loadingService: LoadingService,
    private countriesService: CountriesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.searchOptions.advancedFilter = {
      logic: 'and',
      filters: [
        {
          field: 'consigneeId',
          operator: FilterOperator.Equals,
          value: this.consigneeId,
        },
      ],
    };
    this.getOrders(this.searchOptions);
  }

  getCountry(id: string): string {
    return (
      this.countriesService.countriesArray.find((c) => c.id === id)?.name ?? ''
    );
  }

  getOrders(searchOptions: FilterOptions) {
    this.loadingService.changeLoadingStatus(true);
    this.ordersService
      .getOrders(searchOptions)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (resp: ResponseData<Order>) => {
          this.orders = resp.data;
          const { data, ...clone } = resp;
          this.paginationData = clone;
          this.dataSource = new MatTableDataSource(this.orders);
          this.loadingService.changeLoadingStatus(false);
        },
        error: () => {
          this.loadingService.changeLoadingStatus(false);
        },
      });
  }

  sortOrders(field: string) {
    if (field !== this.selectedSort.field) {
      this.selectedSort = { field, mode: 'asc' };
      this.searchOptions.orderBy = [`${this.selectedSort.field}`];
      this.getOrders(this.searchOptions);
      return;
    }
    if (this.selectedSort.mode === 'asc') {
      this.selectedSort = { field, mode: 'desc' };
      this.searchOptions.orderBy = [
        `${this.selectedSort.field} ${this.selectedSort.mode}`,
      ];
      this.getOrders(this.searchOptions);
      return;
    }
    if (this.selectedSort.mode === 'desc') {
      this.selectedSort = { field: '', mode: '' };
      this.searchOptions.orderBy = [];
      this.getOrders(this.searchOptions);
      return;
    }
  }

  getPageSettings(pageSettings: { perPage: number; currentPage: number }) {
    this.searchOptions.pageSize = pageSettings.perPage;
    this.searchOptions.pageNumber = pageSettings.currentPage;
    this.getOrders(this.searchOptions);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
