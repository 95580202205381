export enum FilterOperator {
  Equals = 'Equals',
  NotEqual = '!Equals',
  Contains = 'Contains',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  Any = 'Any',
}
