import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, tap } from 'rxjs';
import { Country, CountryWithGuidelines } from '../models/country.model';
import { CountryGuideline } from '../models/country-guideline.model';
import { FilterOptions } from '../models/filter.model';
import { ResponseData } from '../models/helpers.model';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(private http: HttpClient) {}

  contriesList = new BehaviorSubject<Country[] | null>(null);
  countriesArray: Country[] = [];

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>('/countries').pipe(
      tap((resp: Country[]) => {
        this.contriesList.next(resp);
        this.countriesArray = [...resp];
      })
    );
  }

  getGuidelinesForCountry(id: string): Observable<Array<CountryGuideline>> {
    return this.http.get<Array<CountryGuideline>>(`countries/${id}/guidelines`);
  }

  getCountriesWithGuidelines(
    filterOptions: FilterOptions
  ): Observable<ResponseData<CountryWithGuidelines>> {
    return this.http.post<ResponseData<CountryWithGuidelines>>(
      `/countries/filter`,
      filterOptions
    );
  }
}
