export enum OrderStatus {
  Open = 0,
  Closed = 1,
  Canceled = 2,
}

export enum OrderStatusString {
  Open = '0',
  Closed = '1',
  Canceled = '2',
}
