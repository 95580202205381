<div class="delete-modal modal-el" (click)="backdropClick($event)">
  <div class="delete-info modal-info-box">
    <div class="delete-title">Delete User</div>
    <div class="delete-user-name">{{ user.firstName }} {{ user.lastName }}</div>
    <img src="/assets/img/delete_user.svg" alt="delete user" />
    <div class="delete-hint">
      This will delete user from the list. Are you sure?
    </div>
    <div class="delete-actions form-actions">
      <button mat-stroked-button class="cancel-btn" (click)="closeModal()">
        Cancel
      </button>
      <button mat-button class="action-btn" (click)="deleteUser()">
        Delete User
      </button>
    </div>
  </div>
</div>
