import { Pipe, PipeTransform } from '@angular/core';
import { FieldWithPermission } from 'src/app/models/helpers.model';

@Pipe({
  name: 'fieldsSettingsFilter',
})
export class FieldsSettingsFilterPipe implements PipeTransform {
  transform(items: FieldWithPermission[], filterValue: string): any {
    switch (filterValue) {
      case 'OrderDto':
      case 'OrderItemDto':
      case 'CompanyDto':
      case 'OrderShipperDto':
      case 'OrderBuyerDto':
        return items.filter((item) => item.objectRefName === filterValue);
    }
  }
}
