import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, tap } from 'rxjs';
import { ResponseData } from '../models/helpers.model';
import { ProfileData, User } from '../models/user.model';
import { FilterOptions } from '../models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  isUpdatedUser = new BehaviorSubject<boolean>(false);
  currentUser = new BehaviorSubject<ProfileData | null>(null);

  changeUpdatedStatus(status: boolean) {
    this.isUpdatedUser.next(status);
  }

  getCurrentUser(): Observable<ProfileData> {
    return this.http
      .get<ProfileData>(`/personal/profile`)
      .pipe(tap((resp: ProfileData) => this.currentUser.next(resp)));
  }

  updateCurrentUser(data: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    receiveEmailNotificationWhenOrderDetailsChanged: boolean;
    receiveEmailNotificationWhenOrderFileUploaded: boolean;
    receiveEmailNotificationWhenOrderStageChanged: boolean;
  }): Observable<void> {
    return this.http.put<void>(`/personal/profile`, data);
  }

  changePassword(data: any): Observable<any> {
    return this.http.put<any>(`/personal/change-password`, data);
  }

  getUserById(id: string): Observable<any> {
    return this.http.get<any>(`/users/${id}`);
  }

  editUser(
    id: string,
    data: { firstName: string; lastName: string; phoneNumber: string }
  ): Observable<void> {
    return this.http.put<void>(`/users/${id}`, data);
  }

  updateUserRole(
    id: string,
    data: { companyId: string; roleId: string | null }
  ): Observable<string> {
    return this.http.put<string>(`/users/${id}/set-role`, data, {
      responseType: 'text' as 'json',
    });
  }

  deleteUser(id: string): Observable<void> {
    return this.http.delete<void>(`/users/${id}`);
  }

  filterUsers(filterOptions: FilterOptions): Observable<ResponseData<User>> {
    return this.http.post<ResponseData<User>>(`/users/filter`, filterOptions);
  }

  inviteUser(data: {
    email: string;
    companyId: string;
    roleId: string;
  }): Observable<string> {
    return this.http.post<string>('/users/invite', data, {
      responseType: 'text' as 'json',
    });
  }

  toggleUserStatus(
    id: string,
    data: { isActive: boolean }
  ): Observable<string> {
    return this.http.post<string>(`/users/${id}/set-active-status`, data);
  }

  resetPassword(data: {
    email: string;
    password: string;
    token: string;
  }): Observable<string> {
    return this.http.post<string>(`/users/reset-password`, data, {
      responseType: 'text' as 'json',
    });
  }

  forgotPassword(data: { email: string }): Observable<string> {
    return this.http.post<string>('/users/forgot-password', data, {
      responseType: 'text' as 'json',
    });
  }
}
