<div class="date-range-filter">
  <form [formGroup]="dateForm">
    <mat-form-field appearance="outline">
      <mat-select
        formControlName="dateRangeControl"
        disableRipple
        (selectionChange)="selectRange()"
      >
        <mat-option *ngFor="let dateRange of dateRanges" [value]="dateRange">{{
          dateRange
        }}</mat-option>
      </mat-select>
      <mat-icon matSuffix class="select-icon-replacement">expand_more</mat-icon>
    </mat-form-field>
  </form>
</div>
