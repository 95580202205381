import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  OrderField,
  FieldValue,
  ListOfOrderFields,
} from '../models/field-list.model';
import { FieldType } from '../enums/field-type';

@Injectable({
  providedIn: 'root',
})
export class OrderFieldService {
  constructor(private http: HttpClient) {}

  getFields(): Observable<OrderField[]> {
    return this.http.get<OrderField[]>('/orderdynamicfields');
  }

  getField(id: string): Observable<OrderField> {
    return this.http.get<OrderField>(`/orderdynamicfields/${id}`);
  }

  getFieldValues(id: string): Observable<FieldValue[]> {
    return this.http.get<FieldValue[]>(`/orderdynamicfields/${id}/values`);
  }

  createField(data: { name: string; type: FieldType }): Observable<OrderField> {
    return this.http.post<OrderField>('/orderdynamicfields', data);
  }

  updateField(id: string, data: { name: string }): Observable<string> {
    return this.http.put<string>(`/orderdynamicfields/${id}`, data, {
      responseType: 'text' as 'json',
    });
  }

  deleteField(id: string): Observable<string> {
    return this.http.delete<string>(`/orderdynamicfields/${id}`, {
      responseType: 'text' as 'json',
    });
  }

  getListOfDynamicFields(): Observable<ListOfOrderFields[]> {
    return this.http.get<ListOfOrderFields[]>('/orderfields/list');
  }
}
