import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Output() selectedRange = new EventEmitter<{ gte: string; lte: string }>();
  dateRanges = ['All Time', 'Last Month', 'Last Week', 'Last Year'];
  dateForm = new FormGroup({
    dateRangeControl: new FormControl(this.dateRanges[0]),
  });

  constructor() {}

  ngOnInit(): void {
    this.selectRange();
  }

  selectRange() {
    const today = new Date();
    const todayPlusOneDay = new Date(
      new Date(today).setDate(today.getDate() + 1)
    );
    const range = this.dateForm.controls.dateRangeControl.value;
    switch (range) {
      case 'Last Week':
        this.selectedRange.emit({
          gte: new Date(new Date(today).setDate(today.getDate() - 7))
            .toLocaleDateString('en-GB')
            .replaceAll('/', '-')
            .split('-')
            .reverse()
            .join('-'),
          lte: todayPlusOneDay
            .toLocaleDateString('en-GB')
            .replaceAll('/', '-')
            .split('-')
            .reverse()
            .join('-'),
        });
        break;
      case 'Last Month':
        this.selectedRange.emit({
          gte: new Date(new Date(today).setMonth(today.getMonth() - 1))
            .toLocaleDateString('en-GB')
            .replaceAll('/', '-')
            .split('-')
            .reverse()
            .join('-'),
          lte: todayPlusOneDay
            .toLocaleDateString('en-GB')
            .replaceAll('/', '-')
            .split('-')
            .reverse()
            .join('-'),
        });
        break;
      case 'Last Year':
        this.selectedRange.emit({
          gte: new Date(new Date(today).setMonth(today.getMonth() - 12))
            .toLocaleDateString('en-GB')
            .replaceAll('/', '-')
            .split('-')
            .reverse()
            .join('-'),
          lte: todayPlusOneDay
            .toLocaleDateString('en-GB')
            .replaceAll('/', '-')
            .split('-')
            .reverse()
            .join('-'),
        });
        break;
      case 'All Time':
        this.selectedRange.emit({ gte: '', lte: '' });
        break;
      default:
        break;
    }
  }
}
